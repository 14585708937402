import React, { useState, useEffect } from "react";
import "./IntegrationChatGPTSetupLeadGeneration.scss";
import { Tooltip } from 'reactstrap';
import { FiInfo, FiTrash2, FiPlus, FiChevronUp, FiSave } from "react-icons/fi";
import { doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import { connect } from 'react-redux';
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import IconDragDrop from '../../../../assets/img/icon-menu-dragdrop.svg';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import PopupDiscard from "../../../reuseableComponent/popupDiscard/PopupDiscard";
import CustomReactSelect from "../../../reuseableComponent/CustomReactSelect/CustomReactSelect";
import FiQuestion from "../../../../assets/img/fi-question.svg";

const IntegrationChatGPTSetupLeadGeneration = (props) => {

    const VARIABLE_LIST = [
        {
            label: "Name",
            value: "name"
        },
        {
            label: "Company",
            value: "company"
        },
        {
            label: "Job Role",
            value: "job_role"
        },
        {
            label: "User's Needs",
            value: "user_need"
        },
        {
            label: "Email",
            value: "email"
        },
        {
            label: "Phone Number",
            value: "phone_number"
        },
        {
            label: "Date",
            value: "date"
        }
    ];

    let [isLoadingGetData, setIsLoadingGetData] = useState(true);
    let [isLoadingSavedData, setIsLoadingSavedData] = useState(false);
    let [errorMessageData, setErrorMessageData] = useState({
        isEnable: true,
        criteria: [
            {
                variable: [
                    {
                        variable: {
                            label: "",
                            value: ""
                        },
                        description: "",
                        question: ""
                    }
                ]
            }
        ]
    });
    let [draggingItem, setDraggingItem] = useState(null);
    let [isShowModalDiscard, setIsShowModalDiscard] = useState(false);

    useEffect(() => {
        fetchSavedLeadsGeneration();
    }, [props.match.params.orgID]);

    useEffect(() => {
        // setErrorMessageData(_errorMessageData);
    }, [props.leadsGenerationSettings]);

    const fetchSavedLeadsGeneration = () => {
        setIsLoadingGetData(true);

        // // TODO: GET LEAD GENERATIONS API
        props.getSavedLeadGeneration((response) => {
            let dataResult = response.dataResult;
            if (dataResult.error.message) {
                doToast(dataResult.error.message, 'fail');
            }
            setIsLoadingGetData(false);
        });
    }

    const toggleModalDiscard = () => {
        setIsShowModalDiscard(!isShowModalDiscard);
    }

    const onChangeText = (e, i, id, parentIndex) => {
        let _data = { ...props.leadsGenerationSettings };
        _data.criteria[parentIndex].variables[i][id] = e.target.value;
        props.setLeadsGenerationSettings(_data);

        // let _errorMessageData = { ...errorMessageData };
        // _error.criteria[parentIndex].variables[i][id] = "";
        // setErrorMessageData(_errorMessageData);
    }

    const onSelectTopic = (e, i) => {
        // let _data = { ...props.leadsGenerationSettings };
        // _data.variables[i].variable = e;
        // props.setLeadsGenerationSettings(_data);

        // let _errorMessageData = { ...errorMessageData };
        // _errorMessageData.variables[i].variable = "";
        // setErrorMessageData(_errorMessageData);
    }

    const resizeQuestionTextArea = (index) => {
        const textarea = document.getElementById(`question-${index}`);
        if (!textarea) {
            return;
        }
        if (textarea.offsetHeight === textarea.scrollHeight) {
            return;
        }
        textarea.style.height = "1px"; // Reset height
        if (textarea.scrollHeight <= 48) {
            textarea.style.height = "48px";
        }
        else {
            textarea.style.height = "" + textarea.scrollHeight + "px";
        }
    }

    const validateContinue = () => {
        let _errorMessageData = { ...errorMessageData };
        let errorClass = false;

        if (props.leadsGenerationSettings.isEnable) {
            // props.leadsGenerationSettings.variable.map((value, index) => {
            //     if (!value.variable.value) {
            //         if (_errorMessageData && errorMessageData.variable && errorMessageData.variable.length > index) {
            //             _errorMessageData.variables[index].variable = "This field is required"
            //         }
            //         if (!errorClass) {
            //             errorClass = `variable-wrapper-${index}`;
            //         }
            //     }
            //     if (!value.description) {
            //         if (_errorMessageData && errorMessageData.variable && errorMessageData.variable.length > index) {
            //             _errorMessageData.variables[index].description = "This field is required"
            //         }
            //         if (!errorClass) {
            //             errorClass = `variable-wrapper-${index}`;
            //         }
            //     }
            //     if (!value.question) {
            //         if (_errorMessageData && errorMessageData.variable && errorMessageData.variable.length > index) {
            //             _errorMessageData.variables[index].question = "This field is required"
            //         }
            //         if (!errorClass) {
            //             errorClass = `variable-wrapper-${index}`;
            //         }
            //     }
            // });
        }

        setErrorMessageData(_errorMessageData);

        if (errorClass) {
            scrollToClass(`.${errorClass}`);
        }
        else {
            props.setActiveTab(3);
        }
    }

    const toggleConfig = () => {
        let _data = { ...props.leadsGenerationSettings };
        _data.isEnabled = !_data.isEnabled;
        props.setLeadsGenerationSettings(_data);

        if (!_data.isEnable) {
            let _errorMessageData = { ...errorMessageData };
            // _errorMessageData.variable.map((value) => {
            //     value.variable = "";
            //     value.description = "";
            //     value.question = "";
            // });
            setErrorMessageData(_errorMessageData);
        }
    }

    const addAnotherCriteria = () => {
        let _data = { ...props.leadsGenerationSettings };
        _data.criteria.push({
            "text": "",
            "isEnabled": false,
            "sendQuestionMethod": "",
            "shouldSummarize": false,
            "shouldHandover": false,
            "handoverTopicID": 0,
            "isWebhookEnabled": false,
            "webhookURL": "",
            "variables": [
                {
                    "name": "",
                    "description": "",
                    "isRequired": false
                },
            ]
        });
        props.setLeadsGenerationSettings(_data);
    }

    const addAnotherVariable = (parentIndex) => {
        let _data = { ...props.leadsGenerationSettings };
        _data.criteria[parentIndex].variables.push({
            description: "",
            isRequired: false,
            name: ""
        });
        props.setLeadsGenerationSettings(_data);

        let _errorMessageData = { ...errorMessageData };
        // _errorMessageData.criteria[parentIndex].variables.push({
        //     description: "",
        //     isRequired: false,
        //     name: ""
        // });
        setErrorMessageData(_errorMessageData);
    }

    const onDeleteVariable = (i, parentIndex) => {
        let _data = { ...props.leadsGenerationSettings };
        _data.criteria[parentIndex].variables.splice(i, 1)
        props.setLeadsGenerationSettings(_data);

        let _errorMessageData = { ...errorMessageData };
        // _errorMessageData.criteria[parentIndex].variables.splice(i, 1)
        setErrorMessageData(_errorMessageData);
    }

    const filterVariableSelection = (i) => {
        let printVar = [];

        // VARIABLE_LIST.map((val) => {
        //     let findIndexVar = props.leadsGenerationSettings.variable.findIndex(_v => _v.variable.value === val.value);

        //     if (findIndexVar === -1 || findIndexVar === i) {
        //         printVar.push(val);
        //     }
        // })

        return printVar;
    }

    const onVariableDragStart = (e, item) => {
        setDraggingItem(item);
        e.dataTransfer.setData('text/plain', '');
    }

    const onVariableDragEnd = (e) => {
        setDraggingItem(null);
    }

    const onVariableDragOver = (e) => {
        e.preventDefault();
    }

    const onVariableDrop = (e, item) => {
        if (!draggingItem) {
            return;
        }

        let _data = { ...props.leadsGenerationSettings };
        const currentIndex = _data.variable.indexOf(draggingItem);
        const targetIndex = _data.variable.indexOf(item);

        if (currentIndex !== -1 && targetIndex !== -1) {
            _data.variable.splice(currentIndex, 1);
            _data.variable.splice(targetIndex, 0, draggingItem);
            props.setLeadsGenerationSettings(_data);

            let _errorMessageData = { ...errorMessageData };
            const draggedErrorData = _errorMessageData.variable[currentIndex];
            _errorMessageData.variable.splice(currentIndex, 1);
            _errorMessageData.variable.splice(targetIndex, 0, draggedErrorData);
            setErrorMessageData(_errorMessageData);
        }
    }

    const printVariable = (v, i, parentIndex) => {
        return (
            <div
                key={`variable-wrap-${i}`}
                className={`variable-wrapper variable-wrapper-${i} form-content-wrapper`}
                // draggable={props.leadsGenerationSettings.variable.length > 1 && props.leadsGenerationSettings.isEnable}
                onDragStart={(e) => onVariableDragStart(e, v)}
                onDragEnd={(e) => onVariableDragEnd(e)}
                onDragOver={(e) => onVariableDragOver(e)}
                onDrop={(e) => onVariableDrop(e, v)}
            >
                <label>
                    <b>Variable</b>
                </label>

                {(i != - 0 && props.leadsGenerationSettings.isEnabled) &&
                    <FiTrash2
                        className={`button-delete`}
                        onClick={() => onDeleteVariable(i, parentIndex)}
                    />
                }

                <div className="input-text-variable">
                    <input
                        type="text"
                        placeholder="E.g. Full Name"
                        // className={` ${(errorMessageData.variable && errorMessageData.variable.length > i && errorMessageData.variables[i] && errorMessageData.variables[i].description) ? "border-red" : ""}`}
                        value={v.name}
                        onChange={(e) => onChangeText(e, i, "name", parentIndex)}
                        disabled={!props.leadsGenerationSettings.criteria[parentIndex].isEnabled || !props.leadsGenerationSettings.isEnabled}
                    />
                </div>

                {/* <CustomSelectOption
                    optionListProps={filterVariableSelection(i)}
                    valueDropdownProps={v.variable}
                    placeholderProps={'Select variable'}
                    onClickDropDownListOptionProps={(e) => onSelectTopic(e, i)}
                    specificClassNameProps="custom-select-variable-topic"
                    dropdownError={""}
                    // _className={(errorMessageData.variable && errorMessageData.variable.length > i && errorMessageData.variables[i] && errorMessageData.variables[i].variable) ? "border-red" : ""}
                    isDisabled={!props.leadsGenerationSettings.isEnable}
                /> */}
                {/* {
                    (errorMessageData.variable && errorMessageData.variable.length > i && errorMessageData.variables[i] && errorMessageData.variables[i].variable) &&
                    <ErrorOrInfoComp
                        text={errorMessageData.variables[i].variable}
                        _className="red-text"
                        icon={<FiAlertCircle />}
                    />
                } */}

                <label>
                    <b>Description</b>

                    {/* <span className={`${i !== 0 ? "right-18" : ""}`}>{v.text.length}/25 character</span> */}
                </label>
                <textarea
                    placeholder="Describe your variable here (E.g. Your potential customer’s full name according to legal documents)"
                    // className={` ${(errorMessageData.variable && errorMessageData.variable.length > i && errorMessageData.variables[i] && errorMessageData.variables[i].description) ? "border-red" : ""}`}
                    value={v.description}
                    onChange={(e) => onChangeText(e, i, "description", parentIndex)}
                    disabled={!props.leadsGenerationSettings.criteria[parentIndex].isEnabled || !props.leadsGenerationSettings.isEnabled}
                    rows={3}
                />
                {/* {
                    (errorMessageData.variable && errorMessageData.variable.length > i && errorMessageData.variables[i] && errorMessageData.variables[i].description) ?
                        <ErrorOrInfoComp
                            text={errorMessageData.variables[i].description}
                            _className="red-text"
                            icon={<FiAlertCircle />}
                        />
                        :
                        <ErrorOrInfoComp
                            text="Description helps explain this variable's use."
                            icon={<FiInfo />}
                        />
                } */}

                {/* {
                    (props.leadsGenerationSettings.isEnable && props.leadsGenerationSettings.variable.length > 1) &&
                    <>
                        <FiTrash2
                            className='button-delete'
                            onClick={() => onDeleteVariable(i)}
                        />
                        <img
                            src={IconDragDrop}
                            className="drag-indicator"
                            alt=""
                            draggable={false}
                        />
                    </>
                } */}

                <div className="criteria-switch-wrapper this-question">
                    <label className="custom-switch-wrapper">
                        <input
                            type="checkbox"
                            checked={v.isRequired}
                            onChange={() => {
                                let _leadGen = { ...props.leadsGenerationSettings };
                                _leadGen.criteria[parentIndex].variables[i].isRequired = !_leadGen.criteria[parentIndex].variables[i].isRequired;
                                props.setLeadsGenerationSettings(_leadGen);
                            }}
                            disabled={!props.leadsGenerationSettings.isEnabled}
                        />
                        <span className="custom-switch round"></span>
                    </label>

                    <p className="switch-label margin-0">This question is required</p>
                </div>

                {/* <img
                    src={IconDragDrop}
                    className="drag-indicator"
                    alt=""
                    draggable={false}
                /> */}
            </div>
        )
    }

    let printCriteria = (vCriteria, iCriteria) => {
        console.log("vsendQuestionMethod", vCriteria.sendQuestionMethod)
        return (
            <div className="criteria-wrapper" key={`criteria-${iCriteria}`}>
                <label className={``}>
                    <b>Criteria #{iCriteria + 1}</b>
                </label>

                <div className="form-content-wrapper input-criteria">
                    <input
                        type="text"
                        className=""
                        onChange={(e) => {
                            let _leadGen = { ...props.leadsGenerationSettings };
                            _leadGen.criteria[iCriteria].text = e.target.value;
                            props.setLeadsGenerationSettings(_leadGen);
                        }}
                        value={vCriteria.text}
                        disabled={!props.leadsGenerationSettings.isEnabled}
                        placeholder="E.g. When a customer ask about loyalto program"
                    />

                    <FiChevronUp
                        className={`show-more-criteria ${props.leadsGenerationSettings.criteria[iCriteria].showMore === false ? "show-more-criteria-hide" : ""}`}
                        onClick={() => {
                            let _leadGen = { ...props.leadsGenerationSettings };
                            _leadGen.criteria[iCriteria].showMore = _leadGen.criteria[iCriteria].showMore === undefined ? true : !_leadGen.criteria[iCriteria].showMore;
                            props.setLeadsGenerationSettings(_leadGen);
                        }}
                    />
                </div>

                <div className="criteria-switch-wrapper">
                    <label className="custom-switch-wrapper">
                        <input
                            type="checkbox"
                            checked={vCriteria.isEnabled}
                            onChange={() => {
                                let _leadGen = { ...props.leadsGenerationSettings };
                                _leadGen.criteria[iCriteria].isEnabled = !_leadGen.criteria[iCriteria].isEnabled;
                                props.setLeadsGenerationSettings(_leadGen);
                            }}
                            disabled={!props.leadsGenerationSettings.isEnabled}
                        />
                        <span className="custom-switch round"></span>
                    </label>

                    <p className="switch-label">
                        Enable Criteria

                        <img id={`TooltipEnableCriteria${iCriteria}`} src={FiQuestion} className="img-fi-question" alt="" />
                        <Tooltip
                            isOpen={vCriteria.showTooltipCriteria}
                            target={`TooltipEnableCriteria${iCriteria}`}
                            toggle={() => {
                                let _leadGen = {...props.leadsGenerationSettings};
                                _leadGen.criteria[iCriteria].showTooltipCriteria = _leadGen.criteria[iCriteria].showTooltipCriteria === undefined ? true : !_leadGen.criteria[iCriteria].showTooltipCriteria;
                                props.setLeadsGenerationSettings(_leadGen);
                            }}
                            placement="right"
                        >
                            Enabling this lets the bot send the questions when criteria are met
                        </Tooltip>
                    </p>
                </div>

                {(vCriteria.showMore === undefined || vCriteria.showMore === true) &&
                    <>
                        <label className={``}>
                            <b>How do you want to send the questions?</b>
                        </label>

                        <div className={`custom-radio custom-radio-question ${!props.leadsGenerationSettings.isEnabled ? "custom-radio-disabled" : ""}`}>
                            <input type="radio"
                                id={`at_once-${iCriteria}`}
                                name={`question-radio-${iCriteria}`}
                                value={"at_once"}
                                onChange={(e) => {
                                    let _leadGen = { ...props.leadsGenerationSettings };
                                    _leadGen.criteria[iCriteria].sendQuestionMethod = "at_once";
                                    props.setLeadsGenerationSettings(_leadGen);
                                }}
                                checked={props.leadsGenerationSettings.criteria[iCriteria].sendQuestionMethod === "at_once"}
                                disabled={!props.leadsGenerationSettings.isEnabled}
                            />
                            <label htmlFor={`at_once-${iCriteria}`}>
                                <div className="question-radio-label">
                                    <p className={``}>All questions at once</p>
                                    <span className={``}>Questions will be sent together in a single form for the user to complete.</span>
                                </div>
                            </label>
                        </div>

                        <div className={`custom-radio custom-radio-question ${!props.leadsGenerationSettings.isEnabled ? "custom-radio-disabled" : ""}`}>
                            <input type="radio"
                                id={`one_by_one-${iCriteria}`}
                                name={`question-radio-${iCriteria}`}
                                value={"one_by_one"}
                                onChange={(e) => {
                                    let _leadGen = { ...props.leadsGenerationSettings };
                                    _leadGen.criteria[iCriteria].sendQuestionMethod = "one_by_one";
                                    props.setLeadsGenerationSettings(_leadGen);
                                }}
                                checked={props.leadsGenerationSettings.criteria[iCriteria].sendQuestionMethod === "one_by_one"}
                                disabled={!props.leadsGenerationSettings.isEnabled}
                            />
                            <label htmlFor={`one_by_one-${iCriteria}`}>
                                <div className="question-radio-label">
                                    <p className={``}>One at a time</p>
                                    <span className={``}>Questions will be sent one by one, like a conversation, in the order you set.</span>
                                </div>
                            </label>
                        </div>

                        <div className="criteria-switch-wrapper">
                            <label className="custom-switch-wrapper">
                                <input
                                    type="checkbox"
                                    checked={vCriteria.shouldSummarize}
                                    onChange={() => {
                                        let _leadGen = { ...props.leadsGenerationSettings };
                                        _leadGen.criteria[iCriteria].shouldSummarize = !_leadGen.criteria[iCriteria].shouldSummarize;
                                        props.setLeadsGenerationSettings(_leadGen);
                                    }}
                                    disabled={!props.leadsGenerationSettings.isEnabled}
                                />
                                <span className="custom-switch round"></span>
                            </label>

                            <p className="switch-label">Summarize answers received from this criteria</p>
                        </div>

                        <div className="criteria-switch-wrapper">
                            <label className="custom-switch-wrapper">
                                <input
                                    type="checkbox"
                                    checked={vCriteria.shouldHandover}
                                    onChange={() => {
                                        let _leadGen = { ...props.leadsGenerationSettings };
                                        _leadGen.criteria[iCriteria].shouldHandover = !_leadGen.criteria[iCriteria].shouldHandover;
                                        props.setLeadsGenerationSettings(_leadGen);
                                    }}
                                    disabled={!props.leadsGenerationSettings.isEnabled}
                                />
                                <span className="custom-switch round"></span>
                            </label>

                            <p className="switch-label">Handover to agent after bot’s confirmation and summarization</p>
                        </div>

                        {props.leadsGenerationSettings.criteria[iCriteria].shouldHandover &&
                            <div className="criteria-topic-wrap">
                                <label>
                                    <b>Topic</b>
                                </label>
                                <CustomReactSelect
                                    options={props.topicList}
                                    placeholder="Select topic"
                                    onChange={(e) => {
                                        let _leadGen = { ...props.leadsGenerationSettings };
                                        _leadGen.criteria[iCriteria].handoverTopicID = e.value;
                                        props.setLeadsGenerationSettings(_leadGen);
                                    }}
                                    classNamePrefix="custom-select-criteria-topic"
                                    isDisabled={!props.leadsGenerationSettings.isEnabled}
                                    value={
                                        props.topicList.filter(
                                            (obj) => obj.value === vCriteria.handoverTopicID
                                        )
                                    }
                                    blurInputOnSelect={true}
                                    // _className={`input-topic ${errorMessageData.topic !== "" ? "border-red" : ""}`}
                                    _className={`input-topic`}
                                />
                            </div>
                        }

                        <div className="criteria-switch-wrapper">
                            <label className="custom-switch-wrapper">
                                <input
                                    type="checkbox"
                                    checked={vCriteria.isWebhookEnabled}
                                    onChange={() => {
                                        let _leadGen = { ...props.leadsGenerationSettings };
                                        _leadGen.criteria[iCriteria].isWebhookEnabled = !_leadGen.criteria[iCriteria].isWebhookEnabled;
                                        props.setLeadsGenerationSettings(_leadGen);
                                    }}
                                    disabled={!props.leadsGenerationSettings.isEnabled}
                                />
                                <span className="custom-switch round"></span>
                            </label>

                            <p className="switch-label">Send summarized data to webhook</p>
                        </div>

                        {props.leadsGenerationSettings.criteria[iCriteria].isWebhookEnabled &&
                            <div className="webhook-url-wrap form-content-wrapper">
                                <label>
                                    <b>Webhook URL</b>
                                </label>

                                <div className="webhook-input-text-wrap">
                                    <input
                                        type="text"
                                        value={vCriteria.webhookURL}
                                        onChange={(e) => {
                                            let _leadGen = { ...props.leadsGenerationSettings };
                                            _leadGen.criteria[iCriteria].webhookURL = e.target.value;
                                            props.setLeadsGenerationSettings(_leadGen);
                                        }}
                                        placeholder="Insert webhook URL"
                                        disabled={!props.leadsGenerationSettings.isEnabled}
                                    />

                                    {/* <div className="">
                                        <b>Test URL</b>
                                    </div> */}
                                </div>
                            </div>
                        }

                        {vCriteria.variables.length > 0 &&
                            <>
                                {vCriteria.variables.map((vVariable, iVariable) => {
                                    return printVariable(vVariable, iVariable, iCriteria)
                                })}
                            </>
                        }

                        {(props.leadsGenerationSettings.isEnabled) &&
                            <ButtonWithLoadingOrIcon
                                text="Add another variable"
                                onClickAction={() => addAnotherVariable(iCriteria)}
                                className="main-button-26 no-fill-button add-another-variable"
                                icon={{
                                    type: "svg",
                                    src: FiPlus
                                }}
                                position="left"
                            />
                        }
                    </>
                }
            </div>
        )
    }

    console.log("pageleadsGenerationSettings", props.leadsGenerationSettings)

    return (
        <>
            <SectionWrap
                {...props}
                orgID={props.match.params.orgID}
                withHeader
                withSideMenu
                noWrap
                dataOrigin={props.leadsGenerationSettingsOrigin}
                dataAfterChange={JSON.stringify(props.leadsGenerationSettings)}
            >
                {/* <div className="nav-button-wrapper">
                    <BackButton
                        onClick={() => {
                            if (leadsGenerationOrigin !== JSON.stringify(props.leadsGenerationSettings)) {
                                setIsShowModalDiscard(true);
                            }
                            else {
                                props.setActiveTab(1);
                            }
                        }}
                        text={"Back to Prompt Settings"}
                    />

                    <NextButton
                        onClick={validateContinue}
                        text={"Continue to Handover Settings"}
                    />
                </div> */}

                <div className="chatgpt-lead-page-wrapper">
                    {isLoadingGetData ?
                        <RetrieveDataLoading
                            isLoading={isLoadingGetData}
                            errorMessage=""
                            errorCode=""
                        />
                        :
                        <>
                            <p className="top-title">
                                <span className="top-subtitle">
                                    Turning this on will allow chatbot to send Leads Generation question created below to gather data of potential clients.
                                </span>

                                {!isLoadingSavedData &&
                                    <div className="gpt-lead-switch-wrapper">
                                        <label className="custom-switch-wrapper">
                                            <input
                                                type="checkbox"
                                                checked={props.leadsGenerationSettings.isEnabled}
                                                onChange={() => toggleConfig()}
                                            />
                                            <span className="custom-switch round"></span>
                                        </label>
                                    </div>
                                }
                            </p>

                            <ErrorOrInfoComp
                                text="Lead generation interacts with visitors, gathers their details, and identifies potential customers."
                                icon={<FiInfo />}
                                _className=""
                                noBold
                            />

                            {(props.leadsGenerationSettings.criteria.length > 0) &&
                                <div className="criteria-wrap">
                                    {props.leadsGenerationSettings.criteria.map((v, i) => {
                                        return (
                                            printCriteria(v, i)
                                        )
                                    })}
                                </div>
                            }

                            {(props.leadsGenerationSettings.isEnabled) &&
                                <ButtonWithLoadingOrIcon
                                    text="Add another criteria"
                                    onClickAction={() => addAnotherCriteria()}
                                    className="main-button-26 no-fill-button add-another-variable"
                                    icon={{
                                        type: "svg",
                                        src: FiPlus
                                    }}
                                    position="left"
                                />
                            }

                            <ButtonWithLoadingOrIcon
                                className="no-fill-button main-button-40 button-save save-changes-lead"
                                text="Save Changes"
                                icon={{
                                    type: "svg",
                                    src: FiSave
                                }}
                                position="right"
                                onClickAction={() => {
                                    props.validateSaveLeadGenerationsSettings();
                                    // props.setPendingTab(-1);
                                }}
                            />
                        </>
                    }
                </div>
            </SectionWrap>

            <PopupDiscard
                dataOrigin={props.leadsGenerationSettingsOrigin}
                dataAfterChange={JSON.stringify(props.leadsGenerationSettings)}
                isOpenDiscard={isShowModalDiscard}
                toggleDiscard={toggleModalDiscard}
                discardProgress={() => {
                    setIsShowModalDiscard(false);
                    props.setActiveTab(1);
                }}
                discardDesc={props.discardDesc}
            />
        </>
    )
}

const mapStateToProps = state => ({
    allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationChatGPTSetupLeadGeneration)
