import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './ChatRoomMessageOutWabaCarousel.scss';
import { FiInfo } from "react-icons/fi";
import CheckMarkDoubleWhite from '../../../../assets/img/chatroom/icon-double-check-white.svg';
import CheckMarkDoubleDark from '../../../../assets/img/chatroom/icon-double-check-dark.svg';
import CheckMarkDark from '../../../../assets/img/chatroom/icon-check-dark.svg';
import AirplaneDark from '../../../../assets/img/chatroom/icon-airplane-dark.svg';
import MessageDeleteWhite from "../../../../assets/img/icon-notallowed-white.svg";
import FiPhoneOrange from "../../../../assets/img/fi-phone-orange.svg";
import FiLinkOrange from "../../../../assets/img/fi-external-link-orange.svg";
import FiQuickReplyOrange from "../../../../assets/img/fi-quick-reply-orange.svg";
import BroadcastGrey from '../../../../assets/img/sidemenu-icon/new/sub/broadcast-message-grey.svg';
import { IoIosClose } from "react-icons/io";
import { FiVideo, FiImage } from "react-icons/fi";
import { setReplyMessage } from '../../../../redux/actions/reduxActionReplyMessage';
import { connect } from 'react-redux';
import MessageAction from '../../../reuseableComponent/messageAction/MessageAction';
import ChatRoomReplyOutMessage from "../chatRoomReplyOutMessage/chatRoomReplyOutMessage/ChatRoomReplyOutMessage";
import { CHAT_TYPE } from '../../../../helper/HelperConst';
import { printLastMessageName, isOtherAgent } from '../../../../helper/HelperGeneral';
import Helper from '../../../../helper/HelperChat';

var ChatRoomMessageOutWabaCarousel = (props) => {
    const ROLE_LIST = {
        agent: {
            value: "agent",
            label: "Agent"
        },
        organization: {
            value: "organization",
            label: "Organization"
        },
        chatbot: {
            value: "chatbot",
            label: "Chatbot"
        }
    }

    let {
        singleChatDataProps,
        activeUserID,
        onReplyMessage,
        onForwardMessage,
        scrollToReply,
        caseData
    } = props;

    let [previewSrc, setPreviewSrc] = useState("");
    let [isShowModalImage, setIsShowModalImage] = useState(false);
    let [isShowModalVideo, setIsShowModalVideo] = useState(false);
    let [modalInfo, setModalInfo] = useState(false);

    let toggleModalImage = () => {
        setIsShowModalImage(!isShowModalImage);
    }

    let generateModalImage = () => {
        let zoomImage = (e) => {
            var zoomer = e.currentTarget;
            zoomer.style.backgroundSize = '200%';
            var offsetX = e.nativeEvent.offsetX;
            var offsetY = e.nativeEvent.offsetY;

            var x = offsetX / zoomer.offsetWidth * 100
            var y = offsetY / zoomer.offsetHeight * 100
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
        }

        let zoomImageOut = (e) => {
            var zoomer = e.currentTarget;
            zoomer.style.backgroundSize = '0%';
        }

        return (
            <div>
                <Modal isOpen={isShowModalImage} className={'modal-image'} toggle={toggleModalImage}>
                    <ModalBody onClick={() => toggleModalImage()}>
                        <IoIosClose className="close-modal-image" onClick={() => toggleModalImage()} />
                        <div className="image-wrapper">
                            <figure
                                className="zoom"
                                style={{ "backgroundImage": `url(${previewSrc})` }}
                                onMouseMove={(e) => zoomImage(e, previewSrc)}
                                onMouseLeave={(e) => zoomImageOut(e)}
                            >
                                <img
                                    src={previewSrc}
                                    alt=""
                                    className="image-preview-val"
                                />
                            </figure>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    let toggleModalVideo = () => {
        setIsShowModalVideo(!isShowModalVideo);
    }

    let generateModalVideo = () => {
        return (
            <div>
                <Modal isOpen={isShowModalVideo} className={'modal-video'} toggle={toggleModalVideo}>
                    <ModalBody onClick={() => toggleModalVideo()}>
                        <IoIosClose className="close-modal-video" onClick={() => toggleModalVideo()} />
                        <div className="video-wrapper">
                            <video controls>
                                <source src={previewSrc} type="video/mp4" />
                                <source src={previewSrc} type="video/ogg" />
                            </video>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    let renderCarouselOut = (data, i) => {
        let printCarouselButton = (type, text, index) => {
            return (
                <div className="button-carousel" key={`button-carousel-${index}`}>
                    {type === "phone_number" &&
                        <img src={FiPhoneOrange} alt="" />
                    }

                    {type === "url" &&
                        <img src={FiLinkOrange} alt="" />
                    }

                    {type === "quick_reply" &&
                        <img src={FiQuickReplyOrange} alt="" />
                    }

                    {text}
                </div>
            )
        };

        return (
            <div
                className={`
                    message-out-bubble carousel-card
                    ${isOtherAgent(singleChatDataProps.user.xcUserID.replace("agent:", ""), props.myAgentData) ? "message-bubble-blue" : ""} 
                    ${singleChatDataProps.user.userRole.code === "chatbot" || singleChatDataProps.user.userRole.code === "organization" ? "message-bubble-organization" : ""}
                `}
                key={`message-out-bubble-carousel-card-${i}`}
            >
                <div
                    className="carousel-card-image-wrapper"
                    onClick={() => {
                        setPreviewSrc(data.headerFileURL);

                        if (data.headerFormat === "image") {
                            toggleModalImage();
                        } else {
                            toggleModalVideo();
                        }
                    }}
                >
                    <div className="image-wrapper">
                        {data.headerFormat === "image" &&
                            <>
                                <img src={data.headerFileURL} alt="" />

                                <div className="icon-wrapper">
                                    <FiImage />
                                </div>
                            </>
                        }

                        {data.headerFormat === "video" &&
                            <>
                                <video src={data.headerFileURL} alt="" />

                                <div className="icon-wrapper">
                                    <FiVideo />
                                </div>
                            </>
                        }
                    </div>
                </div>

                <p dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(data.bodyText) }} />

                <div className="button-carousel-wrapper">
                    {data.buttons.map((_v, _i) => {
                        return printCarouselButton(_v.type, _v.text, _i);
                    })}
                </div>

                {generateModalImage()}
                {generateModalVideo()}
            </div>
        )
    }

    return (
        <>
            <div className={`chat-room-message-out-wrapper`} id={`message-${singleChatDataProps.localID}`}>
                <Modal className="modal-templated-message"
                    isOpen={modalInfo}
                >
                    <ModalBody>
                        <div>
                            <FiInfo />
                            <b>This is a templated message</b>
                        </div>
                        <p>
                            Templated messages are messages that has been saved and approved by WhatsApp officially and cannot be altered.
                        </p>

                        <button
                            className="orange-button main-button-40"
                            onClick={() => setModalInfo(false)}
                        >
                            Dismiss
                        </button>
                    </ModalBody>
                </Modal>

                {singleChatDataProps.isDeleted ?
                    <div className="message-out-bubble deleted-bubble">
                        <React.Fragment>
                            <img src={MessageDeleteWhite} alt="" className="deleted-icon" />
                            This message was deleted.
                        </React.Fragment>
                    </div>
                    :

                    <div
                        className={`
                            message-out-bubble 
                            ${isOtherAgent(singleChatDataProps.user.xcUserID.replace("agent:", ""), props.myAgentData) ? "message-bubble-blue" : ""} 
                            ${singleChatDataProps.user.userRole.code === "chatbot" || singleChatDataProps.user.userRole.code === "organization" ? "message-bubble-organization" : ""}
                        `}
                    >
                        {(isOtherAgent(singleChatDataProps.user.xcUserID.replace("agent:", ""), props.myAgentData) || (singleChatDataProps.user.userRole.code === "chatbot" || singleChatDataProps.user.userRole.code === "organization")) &&
                            <p className="group-sender-name-wrapper">
                                <b>
                                    {singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value &&
                                        <span className="agent-span">{ROLE_LIST.agent.label} - </span>
                                    }

                                    {singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value &&
                                        <span className="agent-span">{ROLE_LIST.organization.label} - </span>
                                    }

                                    {singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value &&
                                        <span className="agent-span">{ROLE_LIST.chatbot.label} - </span>
                                    }

                                    {printLastMessageName(singleChatDataProps, caseData, true)}
                                </b>
                            </p>
                        }

                        {singleChatDataProps.replyTo.localID !== "" &&
                            <ChatRoomReplyOutMessage
                                message={singleChatDataProps}
                                activeUserID={activeUserID}
                                scrollToReply={scrollToReply}
                            />
                        }

                        {singleChatDataProps.type === CHAT_TYPE.WABA_CAROUSEL_BROADCAST &&
                            <div className="message-title-waba-carousel-broadcast">
                                <img src={BroadcastGrey} alt="" />
                                <b>Broadcasted Message</b>
                            </div>
                        }

                        {/* <span className="message-body" dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(singleChatDataProps.body) }}></span> */}
                        <span className="message-body" dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(singleChatDataProps.body) }}></span>

                        <p className="message-info">
                            {Helper.getDateMonthYear(singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(singleChatDataProps.created)}

                            {(singleChatDataProps.isSending && !singleChatDataProps.isRead) &&
                                <img src={AirplaneDark} alt="" />
                            }

                            {(!singleChatDataProps.isSending && !singleChatDataProps.isDelivered && !singleChatDataProps.isRead) &&
                                <img src={CheckMarkDark} alt="" />
                            }

                            {(!singleChatDataProps.isSending && singleChatDataProps.isDelivered && !singleChatDataProps.isRead) &&
                                <img src={CheckMarkDoubleDark} alt="" />
                            }

                            {singleChatDataProps.isRead &&
                                <img src={CheckMarkDoubleWhite} alt="" />
                            }

                            {/* {!singleChatDataProps.isDelivered &&
                            <img src={CheckMarkDark} alt="" />
                        } */}
                        </p>

                        {/* {singleChatDataProps.type === CHAT_TYPE.WABA_CAROUSEL && */}
                        <div className="message-out-template-banner" onClick={() => { setModalInfo(true) }}>
                            <FiInfo />
                            {/* <div> */}
                            <p>This is a templated message</p>
                            {/* </div> */}
                        </div>
                        {/* } */}

                        <MessageAction
                            singleChatDataProps={singleChatDataProps}
                            _className="message-action-dropdown-right"
                            replyAction={() => onReplyMessage(singleChatDataProps)}
                            forwardAction={() => onForwardMessage(singleChatDataProps)}
                        />
                    </div>
                }
            </div>

            <div className={`chat-room-message-out-meta-carousel-wrapper`} id={`message-${singleChatDataProps.localID}`}>
                {singleChatDataProps.isDeleted ?
                    <div className="message-out-bubble deleted-bubble">
                        <React.Fragment>
                            <img src={MessageDeleteWhite} alt="" className="deleted-icon" />
                            This message was deleted.
                        </React.Fragment>
                    </div>
                    :

                    <div className={`message-out-bubble-carousel-wrapper-out`}>
                        {(singleChatDataProps.data !== "" && singleChatDataProps.data.whatsappbaCarousel) &&
                            singleChatDataProps.data.whatsappbaCarousel.cards.map((v, i) => {
                                return renderCarouselOut(v, i)
                            })
                        }
                    </div>
                }
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    myAgentData: state.myAgentData
});

const mapDispatchToProps = {
    setReplyMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageOutWabaCarousel);
