import React from "react";
import "./CustomReactSelect.scss";
import Select from "react-select";

let CustomReactSelect = (_props) => {
    let { width, options, placeholder, classNamePrefix, isDisabled, value, _className, onChange, isWabaOrSmeChannel } = _props;

    let iconDropOrange = "url('/image/chevron-down-orange.svg') center / contain no-repeat !important";
    let iconDropGrey = "url('/image/chevron-down-grey.svg') center / contain no-repeat !important";

    let styleSelect = () => ({
        indicatorsContainer: provide => ({
            ...provide,
            height: "48px"
        }),
        valueContainer: provide => ({
            ...provide,
            height: "100%"
        }),
        clearIndicator: () => ({
            // display: "none"
        }),
        dropdownIndicator: (provided, { isFocused }) => ({
            ...provided,
            background: isFocused ? iconDropOrange : iconDropGrey,
            transition: "0.25s ease-in-out",
            color: "transparent !important",
            width: "24px",
            height: "24px",
            // transform: isFocused ? "rotate(-180deg)" : ""
        }),
        container: (provided) => ({
            ...provided,
            width: width,
            // zIndex: 1
        }),
        control: (provide, { isFocused }) => ({
            ...provide,
            padding: "0 5px",
            // height: "48px",
            background: isDisabled ? "rgba(25, 25, 25, 0.1)" : "#fff",
            borderRadius: "8px",
            border: isFocused ? "1px solid #ff7e00 !important" : "1px solid rgba(25, 25, 25, 0.1) !important",
            boxShadow: "none !important",
            marginBottom: "0",
            cursor: "pointer"
        }),
        indicatorSeparator: provide => ({ ...provide, display: "none" }),
        menu: provide => ({
            ...provide,
            borderRadius: "8px",
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
            overflow: "hidden",
            margin: 0,
        }),
        option: (provide, state) => {
            let { isSelected } = state;
            let checked = {
                color: "#ff7e00",
                content: '"✔"',
                position: "absolute",
                left: "0",
                top: "50%",
                transform: "translate(100%, -50%)"
            };
            return {
                ...provide,
                position: "relative",
                background: "#fff",
                padding: "12px 15px 12px 35px",
                cursor: "pointer",
                color: isSelected ? "#ff7e00" : "#121212",
                ":hover": { background: "#ffe7d0" },
                ":before": isSelected ? checked : null
            };
        }
    })

    let wabaSmeChannel = (props) => {
        const { innerProps, innerRef } = props;

        return (
            <div ref={innerRef} {...innerProps} className="my-options">
                <p className={`${(props.data.channelStatus === "active" || props.data.channelStatus === "authenticated") ? "" : "grey-text"}`}>
                    {props.data.label}
                </p>

                <div className={`option-badge option-${props.data.channelStatus}`}>
                    {props.data.channelStatusText}
                </div>
            </div>
        );
    }

    let normalList = (props) => {
        const { innerProps, innerRef } = props;

        return (
            <div ref={innerRef} {...innerProps} className="my-options-normal">
                {props.data.label}
            </div>
        );
    }

    return (
        <>
            {isWabaOrSmeChannel ?
                <Select
                    options={options}
                    components={{ Option: wabaSmeChannel }}
                    placeholder={placeholder}
                    onChange={(val) => {
                        if (val.channelStatus === "authenticated" || val.channelStatus === "active") {
                            onChange(val);
                        }
                    }}
                    styles={styleSelect()}
                    classNamePrefix={classNamePrefix}
                    isDisabled={isDisabled}
                    value={value}
                    blurInputOnSelect={true}
                    _className={`${_className}`}
                />
                :
                <Select
                    options={options}
                    components={{ Option: normalList }}
                    placeholder={placeholder}
                    onChange={onChange}
                    styles={styleSelect()}
                    classNamePrefix={classNamePrefix}
                    isDisabled={isDisabled}
                    value={value}
                    blurInputOnSelect={true}
                    _className={`${_className}`}
                />
            }
        </>
    )
};

export default CustomReactSelect;