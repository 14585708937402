import React, { useState, useEffect } from "react";
import "./BroadcastMessagePreview.scss";
import { taptalk, tapCoreMessageManager } from "@taptalk.io/web-sdk";
import { Modal, ModalBody } from "reactstrap";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import TermsWhatsappModal from "../../reuseableComponent/termsWhatsappModal/TermsWhatsappModal";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import CustomSelectOption from "../../reuseableComponent/customSelectOption/CustomSelectOption";
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
// import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import { checkID, doToast } from "../../../helper/HelperGeneral";
import HelperDate from "../../../helper/HelperDate";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import Helper from '../../../helper/HelperChat';
import { WHATSAPP_FILE_SIZE_LIMIT } from "../../../constants/taplive";
import HelperUpload from "../../../helper/HelperUpload";
import ChatService from '../../../services/chat/caseServices';
import IntegrationWhatsappBusinessServices from "../../../services/integration/IntegrationWhatsappBusinessServices";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
// import StartConversationServices from "../../../services/chat/startConversationServices";
import BroadcastMessageServices from "../../../services/newServices/BroadcastMessageServices";
import { FiEye, FiCheck, FiSend, FiUser, FiPaperclip, FiImage, FiVideo, FiPlay, FiDownload, FiUpload, FiAlertCircle, FiFile, FiInfo, FiUploadCloud } from "react-icons/fi";
import Airplane from '../../../assets/img/icon-airplane.svg';
import AirplaneGrey from '../../../assets/img/icon-airplane-grey.svg';
import CheckMarkDoubleWhite from '../../../assets/img/chatroom/icon-double-check-white.svg';
import FiPhoneOrange from "../../../assets/img/fi-phone-orange.svg";
import FiLinkOrange from "../../../assets/img/fi-external-link-orange.svg";
import FiQuickReplyOrange from "../../../assets/img/fi-quick-reply-orange.svg";
import mixpanel from "mixpanel-browser";
import { BROADCAST_HEADER_FORMAT, FAIL_SEND_MESSAGE_REASON } from "../../../constants/message";
import { TAPLIVE_MEDIUM } from "../../../constants/taplive";
import { setModalStartConversationBalance, clearModalStartConversationBalance } from "../../../redux/actions/reduxActionModalStartConversationBalance";
import { connect } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const MESSAGE_TYPE = {
  text: "text",
  image: "image",
  file: "file",
  video: "video"
}

const BroadcastMessage = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [showModalTerms, setShowModalTerms] = useState(false);
  let [isWaitResponseBroadcastMessage, setIsWaitResponseBroadcastMessage] = useState(false);
  let [broadcastMessageData, setBroadcastMessageData] = useState(false);
  let [broadcastMessageDataOrigin, setBroadcastMessageDataOrigin] = useState(false);
  let [isShowModalSending, setIsShowModalSending] = useState(false);
  let [checkedCheckbox, setCheckedCheckbox] = useState(false);
  let [loadingWhatsappPrice, setLoadingWhatsappPrice] = useState(false);
  // let [currentMembership, setCurrentMembership] = useState(false);
  let [percentageUpload, setPercentageUpload] = useState(false);
  let [isUploading, setIsUploading] = useState(false)
  // let [broadcastMessageTemplate, setBroadcastMessageTemplate] = useState(false);
  // let [isLoadTemplateDetail, setIsLoadTemplateDetail] = useState(true);  
  // let [retriveDataError, setRetrieveDataError] = useState({
  //   code: false,
  //   message: false
  // }); 

  let onSelectCustomVariableCarousel = (type, e, parentIndex, cardIndex) => {
    let _broadcastMessageData = { ...broadcastMessageData };

    if (type === "carouselHeaderMedia") {
      let idxBodyParam = broadcastMessageData.customContacts.header.findIndex(v => v.value === e.value);

      _broadcastMessageData.language.language.cards[parentIndex].headerDropdownVal = e;
      _broadcastMessageData.language.language.cards[parentIndex].valueLabel = e.value;
      _broadcastMessageData.language.language.cards[parentIndex].value = e.value;
      _broadcastMessageData.language.language.cards[parentIndex].headerFileURL = idxBodyParam === -1 ? "" : broadcastMessageData.customContacts.body[0][idxBodyParam];
    }

    if (type === "carouselBodyParam") {
      if (_broadcastMessageData.language.language.cardParameters[parentIndex].body.length > 0) {
        _broadcastMessageData.language.language.cardParameters[parentIndex].body[cardIndex].dropdownVal = e;
        _broadcastMessageData.language.language.cardParameters[parentIndex].body[cardIndex].valueLabel = e.value;
        _broadcastMessageData.language.language.cardParameters[parentIndex].body[cardIndex].value = e.value;
      }
    }

    if (type === "carouselButtonParamDynamicURL") {
      if (_broadcastMessageData.language.language.cardParameters[parentIndex].buttons.length > 0) {
        _broadcastMessageData.language.language.cardParameters[parentIndex].buttons[cardIndex].dropdownVal = e;
        _broadcastMessageData.language.language.cardParameters[parentIndex].buttons[cardIndex].valueLabel = e.value;
        _broadcastMessageData.language.language.cardParameters[parentIndex].buttons[cardIndex].value = e.value;
      }
    }

    setBroadcastMessageData(_broadcastMessageData);
  }

  let onSelectCustomVariable = (type, e, i) => {
    let _broadcastMessageData = { ...broadcastMessageData };

    if (type === "head") {
      if (_broadcastMessageData.language.language.parameters.header.length > 0) {
        _broadcastMessageData.language.language.parameters.header[i].dropdownVal = e;
        _broadcastMessageData.language.language.parameters.header[i].valueLabel = e.value;
        _broadcastMessageData.language.language.parameters.header[i].value = e.value;
      }
    }

    if (type === "body") {
      if (_broadcastMessageData.language.language.parameters.body.length > 0) {
        _broadcastMessageData.language.language.parameters.body[i].dropdownVal = e;
        _broadcastMessageData.language.language.parameters.body[i].valueLabel = e.value;
        _broadcastMessageData.language.language.parameters.body[i].value = e.value;
      }
    }

    if (type === "foot") {
      if (_broadcastMessageData.language.language.parameters.footer.length > 0) {
        _broadcastMessageData.language.language.parameters.footer[i].dropdownVal = e;
        _broadcastMessageData.language.language.parameters.footer[i].valueLabel = e.value;
        _broadcastMessageData.language.language.parameters.footer[i].value = e.value;
      }
    }

    if (type === "buttons") {
      if (_broadcastMessageData.language.language.parameters.buttons.length > 0) {
        _broadcastMessageData.language.language.parameters.buttons[i].dropdownVal = e;
        _broadcastMessageData.language.language.parameters.buttons[i].valueLabel = e.value;
        _broadcastMessageData.language.language.parameters.buttons[i].value = e.value;
      }
    }

    setBroadcastMessageData(_broadcastMessageData);
  }

  let toggleModalTerms = () => {
    setShowModalTerms(!showModalTerms);
  }

  const back = (data) => {
    history.push({
      pathname: `/o/${orgID}/operational/broadcast-message/create`,
      state: {
        tab: props.history.location.state.tab,
        broadcastMessageData: data,
        broadcastMessageDataOrigin: broadcastMessageDataOrigin,
        topicListAgent: props.history.location.state.topicListAgent,
        isMultiple: props.location.state.isMultiple,
        whatsappsmeList: props.location.state.whatsappsmeList
      }
    })
  }

  const doMount = async () => {
    setLoadingWhatsappPrice(false);
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/operational/broadcast-message`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  // let runSetCurrentMembership = () => {
  //   let myAgentAccount = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
  //   let _idx = myAgentAccount.memberships.findIndex((val) => val.organization.id === parseInt(orgID.split("-")[0]));

  //   setCurrentMembership({
  //     roleCode: myAgentAccount.memberships[_idx].roleCode
  //   })
  // }

  let changeCheckBoxTerms = (e) => {
    setCheckedCheckbox(e.target.checked)
  }

  // let runTemplateDetail = () => {
  //   setIsLoadTemplateDetail(true);
  //   let _retriveDataError = {...retriveDataError};
  //   let data = {
  //       channelID: broadcastMessageData.channel.id,
  //       templateID: broadcastMessageData.messageTemplate.value
  //     }

  //   IntegrationWhatsappBusinessServices.getTemplateDetail(props.match.params.orgID, data, (response) => {
  //     let dataResult = response.dataResult;
  //     if(dataResult.error.message === "") {
  //       setBroadcastMessageTemplate(dataResult.data)
  //       // setBroadcastMessageList(dataResult.data.broadcasts);
  //       // setTotalItems(dataResult.data.totalItems)
  //     }else {
  //       _retriveDataError.message = dataResult.error.message;
  //       _retriveDataError.code = dataResult.error.code;
  //       setRetrieveDataError(_retriveDataError);
  //     }

  //     setIsLoadTemplateDetail(false);
  //   })
  // }

  useEffect(() => {
    doMount();
    setLoadingWhatsappPrice(false);
  }, [location.pathname]);

  // useEffect(() => {
  //   if (orgID) {
  // runSetCurrentMembership();
  //   }
  // }, [orgID])

  useEffect(() => {
    if (!props.history.location.state) {
      props.history.push(`/`);
    } else {
      let _broadcastMessageData = { ...props.history.location.state.broadcastMessageData };
      let _broadCastMessageDataOrigin = JSON.parse(JSON.stringify(props.history.location.state.broadcastMessageDataOrigin));
      setBroadcastMessageDataOrigin(_broadCastMessageDataOrigin);

      if (_broadcastMessageData.contactType === "contacts") {
        let valDropdownDef = {
          label: "",
          value: ""
        };

        _broadcastMessageData.language.language.parameters.header.map((v) => {
          v.dropdownVal = valDropdownDef;

          return null;
        });

        _broadcastMessageData.language.language.parameters.buttons.map((v) => {
          v.dropdownVal = valDropdownDef;

          return null;
        });

        _broadcastMessageData.language.language.parameters.body.map((v) => {
          v.dropdownVal = valDropdownDef;

          return null;
        })

        _broadcastMessageData.language.language.parameters.footer.map((v) => {
          v.dropdownVal = valDropdownDef;

          return null;
        })
      }

      if (_broadcastMessageData.selectedChannel.value === TAPLIVE_MEDIUM.whatsappba) {
        _broadcastMessageData.language.language.bodyTextNamedOrigin = _broadcastMessageData.language.language.bodyTextNamed;
      }

      if (
        _broadcastMessageData.selectedChannel.value === TAPLIVE_MEDIUM.whatsappba &&
        _broadcastMessageData.language.language.headerFormat !== "text" &&
        _broadcastMessageData.language.language.headerFormat !== "" &&
        _broadcastMessageData.language.language.headerTextNamed === ""
      ) {
        _broadcastMessageData.isDynamicMedia = true;
      } else {
        _broadcastMessageData.isDynamicMedia = false;
      }

      setBroadcastMessageData(_broadcastMessageData);
    }
  }, [props.history]);

  useEffect(() => {
    if (broadcastMessageData && broadcastMessageData.language.language.cards) {
      broadcastMessageData.language.language.cards.map((v, i) => {
        if (v.lastUploadFile && v.lastUploadFile.time) {
          let _data = { ...broadcastMessageData };

          _data.language.language.cards[i].headerFileURL = v.lastUploadFile.url;
          setBroadcastMessageData(_data);
        }
      })
    }
  }, [JSON.stringify(broadcastMessageData)])

  let closeModalBroadcastBalanceModal = () => {
    let _modalStartConversationBalance = { ...props.modalStartConversationBalance };
    _modalStartConversationBalance.confirmation.show = false;
    _modalStartConversationBalance.confirmation.participant = broadcastMessageData.contacts.length;
    _modalStartConversationBalance.confirmation.isLoading = false;

    props.setModalStartConversationBalance(_modalStartConversationBalance);
  }

  let onSubmitBroadcastMessage = () => {
    setIsWaitResponseBroadcastMessage(true);
    setShowModalTerms(false);
    setIsShowModalSending(true);

    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Button] Send Broadcast Message",
        {
          // type: broadcastMessageData.messageType,
          userFullName: myAgentData.account.fullName,
          userID: myAgentData.account.id,
          organizationID: orgID.split("-")[0]
        }
      );
    }

    let newContacts = [];

    broadcastMessageData.contacts.map(value => {
      newContacts.push(value.phone);
      return null;
    })

    let afterResponse = (response) => {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      let dataResult = response.dataResult;

      if (dataResult.error.message === "") {
        if (dataResult.data.success) {
          if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
              "[Action] Send Broadcast Message",
              {
                // type: broadcastMessageData.messageType,
                userFullName: myAgentData.account.fullName,
                userID: myAgentData.account.id,
                organizationID: orgID.split("-")[0]
              }
            );
          }

          doToast(response.dataResult.data.message);

          setTimeout(() => {
            setIsShowModalSending(false);
            history.push({
              pathname: `/o/${orgID}/operational/broadcast-message`,
              state: {
                // message: response.dataResult.data.message,
                // message: broadcastMessageData.selectedChannel.value === "whatsapp" ? response.dataResult.data.message : `Sent ${broadcastMessageData.name} to ${broadcastMessageData.contacts.length} recipient${broadcastMessageData.contacts.length > 1 ? "s" : ""}`,
                userFullName: myAgentData.account.fullName,
                userID: myAgentData.account.id,
                organizationID: orgID.split("-")[0],
                tab: props.history.location.state.tab
              }
            })
          }, 2000)
        } else {
          setTimeout(() => {
            setIsShowModalSending(false);

            if (broadcastMessageData.selectedChannel.value === TAPLIVE_MEDIUM.whatsapp) {
              if (dataResult.data.reason === FAIL_SEND_MESSAGE_REASON.insufficient_balance) {
                let _modalStartConversationBalance = { ...props.modalStartConversationBalance };
                _modalStartConversationBalance.confirmation.show = false;
                _modalStartConversationBalance.confirmation.isLoading = false;
                _modalStartConversationBalance.topupBalance.show = true;

                props.setModalStartConversationBalance(_modalStartConversationBalance);
              } else {
                doToast(dataResult.data.message, "fail");
              }
            } else {
              setIsWaitResponseBroadcastMessage(false);
              doToast(dataResult.data.success, "fail");
            }
          }, 2000);
        }
      } else {
        setIsShowModalSending(false);
        setIsWaitResponseBroadcastMessage(false);
        doToast(dataResult.error.message, "fail");
      }
    }

    let _scheduleDate = broadcastMessageData.scheduleDate ? HelperDate.formatToString(new Date(broadcastMessageData.scheduleDate), "yyyy-MM-dd") : "";
    let _scheduleTime = broadcastMessageData.scheduleTime;
    let _isScheduled = _scheduleDate !== "" && _scheduleTime !== "";

    if (broadcastMessageData.selectedChannel.value === "whatsapp") {
      let data = null;

      if (props.location.state.isMultiple) {
        let _state = props.location.state.broadcastMessageData;
        let targets = [];

        _state.arrayOfChannelRecipient.map((v) => {
          let _segment = [];
          let _topicID = null;

          if (_state.withCase) {

          }

          v.multiSelectSegment.map((_v) => {
            _segment.push(_v.value)

            return null;
          })

          targets.push({
            channelID: v.channel.value,
            userSegmentIDs: _segment,
            topicID: _topicID === null ? parseInt(v.topicID) : null
          })

          return null;
        });

        data = {
          broadcastName: _state.name,
          targets: targets,
          messages: _state.messages,
          withCase: _state.withCase,
          isScheduled: _isScheduled,
          scheduledDate: _scheduleDate,
          scheduledTime: _scheduleTime,
        }

        BroadcastMessageServices.sendBroadcastMessageMulti(orgID, data, (response) => {
          afterResponse(response)
        });
      } else {
        if (broadcastMessageData.contactType === "user_segment") {
          data = {
            broadcastName: broadcastMessageData.name,
            channelID: broadcastMessageData.channel.value,
            recipientType: broadcastMessageData.contactType,
            userSegmentID: broadcastMessageData.segment.id,
            messages: broadcastMessageData.messages,
            withCase: broadcastMessageData.withCase ? true : false,
            isScheduled: _isScheduled,
            scheduledDate: _scheduleDate,
            scheduledTime: _scheduleTime,
            topicID: parseInt(broadcastMessageData.topic.value)
          }
        } else {
          data = {
            broadcastName: broadcastMessageData.name,
            channelID: broadcastMessageData.channel.value,
            recipients: broadcastMessageData.contacts,
            recipientType: broadcastMessageData.contactType,
            messages: broadcastMessageData.messages,
            withCase: broadcastMessageData.withCase ? true : false,
            isScheduled: _isScheduled,
            scheduledDate: _scheduleDate,
            scheduledTime: _scheduleTime,
            topicID: parseInt(broadcastMessageData.topic.value)
          }
        }

        closeModalBroadcastBalanceModal();

        BroadcastMessageServices.sendBroadcastMessage(orgID, data, (response) => {
          afterResponse(response)
        });
      }
    } else {
      let data = null;

      if (props.location.state.isMultiple) {
        let _state = props.location.state.broadcastMessageData;
        let targets = [];

        _state.arrayOfChannelRecipient.map((v) => {
          let _segment = [];

          v.multiSelectSegment.map((_v) => {
            _segment.push(_v.value)

            return null;
          })

          targets.push({
            channelID: v.channel.value,
            userSegmentIDs: _segment
          })

          return null;
        });

        data = {
          broadcastName: _state.name,
          channelID: _state.channel.id,
          phoneNumberID: _state.phoneVal.value,
          userSegmentIDs: targets[0].userSegmentIDs,
          templateID: _state.messageTemplate.value,
          languageCode: _state.language.language.languageCode,
          parameters: _state.language.language.parameters,
          withCase: broadcastMessageData.withCase ? true : false,
          isScheduled: _isScheduled,
          scheduledDate: _scheduleDate,
          scheduledTime: _scheduleTime,
          topicID: parseInt(broadcastMessageData.arrayOfChannelRecipient[0].topicID)
        }

        if (broadcastMessageData.language.language.cards && broadcastMessageData.language.language.cards.length > 0) {
          data.cardParameters = [];

          broadcastMessageData.language.language.cards.map((vCard, iCard) => {
            data.cardParameters.push({
              headerFileURL: vCard.headerFileURL,
              body: [],
              buttons: []
            })

            return null;
          })

          if (broadcastMessageData.language.language.cardParameters.length > 0) {
            broadcastMessageData.language.language.cardParameters.map((vCardParam, iCardParam) => {
              if (vCardParam.body.length > 0) {
                vCardParam.body.map((vCardParamBody) => {
                  data.cardParameters[iCardParam].body.push(vCardParamBody);

                  return null;
                })
              }

              if (vCardParam.buttons.length > 0) {
                vCardParam.buttons.map((vCardParamButtons) => {
                  data.cardParameters[iCardParam].buttons.push(vCardParamButtons)

                  return null;
                })
              }
            })
          }
        }

        BroadcastMessageServices.sendBroadcastMessageWabaMulti(orgID, data, (response) => {
          afterResponse(response)
        });
      } else {
        let data = {};

        if (broadcastMessageData.contactType === "contacts") {
          let _customContact = broadcastMessageData.customContacts;
          let _recipients = [];
          let _indexOf = (array, word) => {
            return array.findIndex(v => v.value === word);
          }

          _customContact.body.map((v, i) => {
            let headerParam = [];
            let bodyParam = [];
            let footerParam = [];
            let buttonParam = [];
            let cardParam = [];

            let _header = broadcastMessageData.language.language.parameters.header.slice();
            if (_header.length > 0) {

              _header.map((_v, _i) => {
                _v.value = _customContact.body[i][_indexOf(_customContact.header, _v.valueLabel)];

                headerParam.push({
                  name: _v.name,
                  value: _customContact.body[i][_indexOf(_customContact.header, _v.valueLabel)]
                })

                return null;
              })
            }

            let _body = broadcastMessageData.language.language.parameters.body.slice();
            if (_body.length > 0) {
              _body.map((_v, _i) => {
                _v.value = _customContact.body[i][_indexOf(_customContact.header, _v.valueLabel)];

                bodyParam.push({
                  name: _v.name,
                  value: _customContact.body[i][_indexOf(_customContact.header, _v.valueLabel)]
                })

                return null;
              })
            }

            let _footer = broadcastMessageData.language.language.parameters.footer.slice();
            if (_footer.length > 0) {
              _footer.map((_v, _i) => {
                _v.value = _customContact.body[i][_indexOf(_customContact.header, _v.valueLabel)];

                footerParam.push({
                  name: _v.name,
                  value: _customContact.body[i][_indexOf(_customContact.header, _v.valueLabel)]
                })

                return null;
              })
            }

            let _buttons = broadcastMessageData.language.language.parameters.buttons.slice();
            if (_buttons.length > 0) {
              _buttons.map((_v, _i) => {
                _v.value = _customContact.body[i][_indexOf(_customContact.header, _v.valueLabel)];

                buttonParam.push({
                  name: _v.name,
                  value: _customContact.body[i][_indexOf(_customContact.header, _v.valueLabel)]
                })

                return null;
              })
            }

            if (broadcastMessageData.language.language.cards && broadcastMessageData.language.language.cards.length > 0) {
              let _cardParam = broadcastMessageData.language.language.cardParameters.slice();

              if (_cardParam.length > 0) {
                _cardParam.map((_vCardParam, _iCardParam) => {
                  cardParam.push({
                    headerFileURL: _customContact.body[i][_indexOf(_customContact.header, broadcastMessageData.language.language.cards[_iCardParam].headerDropdownVal.label)],
                    body: [],
                    buttons: []
                  })

                  if (_vCardParam.body.length > 0) {
                    _vCardParam.body.map((_vCardParamBody, _iCardParamBody) => {
                      _vCardParamBody.value = _customContact.body[i][_indexOf(_customContact.header, _vCardParamBody.valueLabel)];

                      cardParam[_iCardParam].body.push({
                        name: _vCardParamBody.name,
                        value: _customContact.body[i][_indexOf(_customContact.header, _vCardParamBody.valueLabel)]
                      })

                      return null;
                    })
                  }

                  if (_vCardParam.buttons.length > 0) {
                    _vCardParam.buttons.map((_vCardParamButton, _iCardParamButton) => {
                      _vCardParamButton.value = _customContact.body[i][_indexOf(_customContact.header, _vCardParamButton.valueLabel)];

                      cardParam[_iCardParam].buttons.push({
                        name: _vCardParamButton.name,
                        value: _customContact.body[i][_indexOf(_customContact.header, _vCardParamButton.valueLabel)]
                      })

                      return null;
                    })
                  }

                  return null;
                })
              }

              _recipients.push({
                "phone": v[_indexOf(_customContact.header, "phone_number")],
                "fullName": v[_indexOf(_customContact.header, "full_name")],
                "alias": v[_indexOf(_customContact.header, "alias")],
                "parameters": {
                  "header": headerParam,
                  "body": bodyParam,
                  "footer": footerParam,
                  "buttons": buttonParam
                },
                "cardParameters": cardParam,
                "isCardParamReady": true
              })
            } else {
              _recipients.push({
                "phone": v[_indexOf(_customContact.header, "phone_number")],
                "fullName": v[_indexOf(_customContact.header, "full_name")],
                "alias": v[_indexOf(_customContact.header, "alias")],
                "parameters": {
                  "header": headerParam,
                  "body": bodyParam,
                  "footer": footerParam,
                  "buttons": buttonParam
                },
              })
            }

            return null;
          })

          data = {
            broadcastName: broadcastMessageData.name,
            channelID: broadcastMessageData.channel.value,
            templateID: broadcastMessageData.messageTemplate.value,
            languageCode: broadcastMessageData.language.language.languageCode,
            parameters: broadcastMessageData.language.language.parameters,
            withCase: broadcastMessageData.withCase,
            recipientType: "users",
            userSegmentID: broadcastMessageData.segment.id,
            isScheduled: _isScheduled,
            scheduledDate: _scheduleDate,
            scheduledTime: _scheduleTime,
            topicID: parseInt(broadcastMessageData.topic.value),
            phoneNumberID: broadcastMessageData.phoneVal.value,
            recipients: _recipients,
            isCardParamReady: broadcastMessageData.language.language.cards && broadcastMessageData.language.language.cards.length > 0
          }
        } else {
          data = {
            broadcastName: broadcastMessageData.name,
            channelID: broadcastMessageData.channel.value,
            phones: newContacts,
            templateID: broadcastMessageData.messageTemplate.value,
            languageCode: broadcastMessageData.language.language.languageCode,
            parameters: broadcastMessageData.language.language.parameters,
            withCase: broadcastMessageData.withCase,
            recipientType: broadcastMessageData.contactType,
            userSegmentID: broadcastMessageData.segment.id,
            isScheduled: _isScheduled,
            scheduledDate: _scheduleDate,
            scheduledTime: _scheduleTime,
            topicID: parseInt(broadcastMessageData.topic.value),
            phoneNumberID: broadcastMessageData.phoneVal.value
          }
        }

        if (data.parameters.footer.length > 0) {
          data.parameters.footer.map(v => {
            v.value = v.value.trim();
            return null;
          })
        }

        if (data.parameters.body.length > 0) {
          data.parameters.body.map(v => {
            v.value = v.value.trim();
            return null;
          })
        }

        if (data.parameters.header.length > 0) {
          data.parameters.header.map(v => {
            v.value = v.value.trim();
            return null;
          })
        }

        if (broadcastMessageData.messageTemplate.channel.categoryCode === "AUTHENTICATION") {
          delete data.parameters.buttons;
        }

        if (!data.isCardParamReady && broadcastMessageData.language.language.cards && broadcastMessageData.language.language.cards.length > 0) {
          data.cardParameters = [];

          broadcastMessageData.language.language.cards.map((vCard) => {
            data.cardParameters.push({
              headerFileURL: vCard.headerFileURL,
              body: [],
              buttons: []
            })

            return null;
          })

          if (broadcastMessageData.language.language.cardParameters.length > 0) {
            broadcastMessageData.language.language.cardParameters.map((vCardParam, iCardParam) => {
              if (vCardParam.body.length > 0) {
                vCardParam.body.map((vCardParamBody) => {
                  data.cardParameters[iCardParam].body.push(vCardParamBody);

                  return null;
                })
              }

              if (vCardParam.buttons.length > 0) {
                vCardParam.buttons.map((vCardParamButtons) => {
                  data.cardParameters[iCardParam].buttons.push(vCardParamButtons)

                  return null;
                })
              }
            })
          }
        }

        if (broadcastMessageData.contactType === "contacts") {
          if (!data.isCardParamReady) {
            data.recipients.map((vRecipient) => {
              vRecipient.cardParameters = data.cardParameters;

              return null;
            })
          }

          BroadcastMessageServices.sendBroadcastMessageWabaCustomPerRecipient(orgID, data, (response) => {
            afterResponse(response)
          })
        } else {
          BroadcastMessageServices.sendBroadcastMessageWaba(orgID, data, (response) => {
            afterResponse(response)
          })
        }
      }
    }
  }

  let renderBubble = (data) => {
    let printFileName = () => {
      let _name = data.filename;
      return _name.split('.').slice(0, -1).join('.');
    }

    return (
      data.type === MESSAGE_TYPE.text ?
        <div className="right-message-bubble-wrapper">
          <div
            className={`right-message-bubble text`}
          >
            <p className="message-body" dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(data.body) }} />

            <p className="message-info">
              {HelperDate.formatToString(new Date(), "dd/MM/yyyy")} <span className="centered-dot" /> {HelperDate.formatToString(new Date(), "HH:mm")}

              <img src={CheckMarkDoubleWhite} alt="" />
            </p>
          </div>
        </div>
        :
        (data.type === MESSAGE_TYPE.file ?
          <div className="right-message-bubble-wrapper">
            <div className={`right-message-bubble file-bubble`}>
              <div className="file-wrapper">
                <FiFile />
              </div>

              <div className="file-info-wrapper">
                <p className="filename-text">
                  <b>{printFileName()}</b>
                </p>

                <p className="filezise-filetype">
                  {Helper.bytesToSize(data.fileData.size)}
                </p>
              </div>

              <p className="message-info">
                {HelperDate.formatToString(new Date(), "dd/MM/yyyy")} <span className="centered-dot" /> {HelperDate.formatToString(new Date(), "HH:mm")}

                <img src={CheckMarkDoubleWhite} alt="" />
              </p>
            </div>
          </div>
          :
          <div className="right-message-bubble-wrapper">
            <div className={`right-message-bubble image-bubble ${data.caption === "" ? `without-caption` : ''}`}>
              <div className="bubble-image-wrapper">
                <img src={data.body} alt="" className="image-content" />
              </div>

              {data.caption !== "" &&
                <p className="message-body" dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(data.caption) }} />
              }

              <p className="message-info">
                {HelperDate.formatToString(new Date(), "dd/MM/yyyy")} <span className="centered-dot" /> {HelperDate.formatToString(new Date(), "HH:mm")}

                <img src={CheckMarkDoubleWhite} alt="" />
              </p>
            </div>
          </div>
        )
    )
  }

  let printCarouselDetail = (_data, i) => {
    let printCarouselDetailButton = (type, text, indexButton) => {
      return (
        <div className="button-carousel" key={`whatsapp-preview-carousel-card-button-${i}-${indexButton}`}>
          {type === "phone_number" &&
            <img src={FiPhoneOrange} alt="" />
          }

          {type === "url" &&
            <img src={FiLinkOrange} alt="" />
          }

          {type === "quick_reply" &&
            <img src={FiQuickReplyOrange} alt="" />
          }

          {text}
        </div>
      )
    };

    return (
      <div className={`carousel-card`} key={`whatsapp-preview-carousel-card-${i}`}>
        <div className="carousel-card-image-wrapper">
          <div className="image-video-wrapper a">
            {/* {_data.headerFileURL ? */}
            <>
              {_data.headerFormat === "image" &&
                <>
                  <img src={_data.headerLocalFile ? window.URL.createObjectURL(_data.headerLocalFile) : _data.headerFileURL} alt="" />

                  {/* <div className="icon-wrapper">
                      <FiImage />
                    </div> */}
                </>
              }

              {_data.headerFormat === "video" &&
                <>
                  <video src={_data.headerFileURL !== "" ? _data.headerFileURL : _data.headerLocalFile.fileSrc} />

                  <div className="icon-wrapper">
                    <FiVideo />
                  </div>
                </>
              }
            </>
            {/* : */}
            {/* "" */}
            {/* } */}
          </div>
        </div>

        <p className="carousel-body-text">
          {_data.bodyTextNamed}
        </p>

        {(_data.buttons && _data.buttons.length) &&
          <div className="button-carousel-wrapper">
            {_data.buttons.map((_v, _i) => {
              return (
                printCarouselDetailButton(_v.type, _v.text, _i)
              )
            })}
          </div>
        }
      </div>
    )
  }

  let renderBubbleBa = (data) => {
    return (
      <>
        <div className="right-message-bubble-wrapper">
          <div
            className={`
              right-message-bubble 
              ${broadcastMessageData.language.language.headerFormat !== "text" ? "bubble-media" : ""}
            `}
          >
            {(
              broadcastMessageData.language.language.headerFormat === "text" || broadcastMessageData.language.language.headerFormat === ""
            ) ?
              <b className="message-body" dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(getHeaderValue(data.language.language)) }} />
              :
              (broadcastMessageData.language.language.headerFormat === "image" ?
                <div className={`preview-image-wrapper ${broadcastMessageData.language.language.file ? "no-background" : ""}`}>
                  {!broadcastMessageData.language.language.file ?
                    (broadcastMessageData.language.language.headerTextNamed === "" ?
                      <div className="preview-placeholder">
                        <FiImage />
                        <br />
                        Your image goes here
                      </div>
                      :
                      <img src={broadcastMessageData.language.language.headerTextNamed} alt="" />
                    )
                    :
                    <img src={window.URL.createObjectURL(broadcastMessageData.language.language.file)} alt="" />
                  }
                </div>
                :
                (broadcastMessageData.language.language.headerFormat === "video" ?
                  <div className={`preview-video-wrapper a ${broadcastMessageData.language.language.file ? "no-background" : ""}`}>
                    {!broadcastMessageData.language.language.file ?
                      (broadcastMessageData.language.language.headerTextNamed === "" ?
                        <div className="preview-placeholder">
                          <FiVideo />
                          <br />
                          Your video goes here
                        </div>
                        :
                        <>
                          <video src={broadcastMessageData.language.language.headerTextNamed} />

                          <div className="play-wrapper">
                            <FiPlay />
                          </div>
                        </>
                      )
                      :
                      <>
                        <video src={broadcastMessageData.language.language.headerTextNamed} />

                        <div className="play-wrapper">
                          <FiPlay />
                        </div>
                      </>
                    }
                  </div>
                  :
                  <div className="preview-document-wrapper">
                    <div className="download-wrapper">
                      <FiDownload />
                    </div>

                    <div className={`file-info-wrapper ${!broadcastMessageData.language.language.file ? "preview-without-file" : ""}`}>
                      <p className="file-name">
                        <b>
                          {broadcastMessageData.language.language.file ?
                            broadcastMessageData.language.language.file.name.split(".")[0]
                            :
                            "Your document goes here"
                          }
                        </b>
                      </p>

                      {broadcastMessageData.language.language.file &&
                        <p className="file-size">
                          {Helper.bytesToSize(broadcastMessageData.language.language.file.size)} {broadcastMessageData.language.language.file.name.split(".")[1]}
                        </p>
                      }
                    </div>
                  </div>
                )
              )
            }

            <p className="message-body" dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(data.language.language.bodyTextNamed) }} />

            <p className="message-body" dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(data.language.language.footerTextNamed) }} />

            <p className="message-info">
              {HelperDate.formatToString(new Date(), "dd/MM/yyyy")} <span className="centered-dot" /> {HelperDate.formatToString(new Date(), "HH:mm")}

              <img src={CheckMarkDoubleWhite} alt="" />
            </p>
          </div>
        </div>

        {(data.language.language.cards && data.language.language.cards.length > 0) &&
          <>
            <div className="carousel-wrapper">
              {data.language.language.cards.map((v, i) => {
                return printCarouselDetail(v, i);
              })}
            </div>
          </>
        }
      </>
    )
  }

  let getHeaderValue = (item) => {
    if (item.headerFormat === BROADCAST_HEADER_FORMAT.image) {
      if (item.headerTextNamed) return item.headerTextNamed
      else if (item.parameters.header
        && item.parameters.header[0]
        && item.parameters.header[0].name) return `{{${item.parameters.header[0].name}}}`
      else return ""
    } else {
      if (item.headerTextNamed) return item.headerTextNamed
      else return ""
    }
  }

  let insertHeaderVariable = (id = "", value = "", index = "") => {
    let sourceText =
      (broadcastMessageData.language.language.headerFormat === BROADCAST_HEADER_FORMAT.image ?
        `{{${broadcastMessageData.language.language.parameters.header[0].number}}}`
        :
        broadcastMessageData.language.language["headerTextIndexed"])
      ;

    let _data = { ...broadcastMessageData };
    let replace = "";
    let re, input;

    for (var i = 1; i <= _data.language.language.parameters.header.length; i++) {
      if (id === "") {
        input = "preview-header-" + String(i - 1);
        input = document.getElementById(input).value;
      } else {
        input = value.value;
        onSelectCustomVariable("head", value, index);
      }

      if (input.includes("    ") || input.includes("\t") || input.includes("&emsp;") || input.includes("\n")) {
        doToast(`Parameter {{${_data.language.language.parameters.header[i - 1].name}}} cannot have new-line/tab characters or 4 consecutive spaces`, "fail");
      }

      // let variableName = _data.language.language.parameters.header[i - 1].name;
      replace = `\\{\\{${i}\\}\\}`;
      re = new RegExp(replace, "g");

      if (id === "") {
        _data.language.language.parameters.header[i - 1].value = input;
      } else {
        // if custom contact
        if (broadcastMessageData.customContacts.contactsFileName) {
          let idxBodyParam = broadcastMessageData.customContacts.header.findIndex(v => v.value === value.value);
          input = broadcastMessageData.customContacts.body[0][idxBodyParam];

          _data.language.language.parameters.header[index].value = input;
        }
      }

      if (input) {
        if (broadcastMessageData.customContacts.contactsFileName) {
          if (index === (i - 1)) {
            sourceText = sourceText.replace(re, input);
          } else {
            if (broadcastMessageData.language.language.parameters.body[i - 1].value) {
              sourceText = sourceText.replace(re, broadcastMessageData.language.language.parameters.body[i - 1].value)
            }
          }
        } else {
          sourceText = sourceText.replace(re, input);
        }
      } else {
        let param = broadcastMessageData.language.language.parameters.header[index].name;
        sourceText = sourceText.replace(re, `{{${param}}}`);
      }
    }

    if (id === "") {
      _data.language.language.headerTextNamed = sourceText;
    } else {
      if (broadcastMessageData.customContacts.contactsFileName) {
        _data.language.language.headerTextNamed = sourceText;
      }
    }

    setBroadcastMessageData(_data);
  };

  let insertBodyVariable = (id = "", value = "", index = "") => {
    let sourceText = broadcastMessageData.language.language["bodyTextNamedOrigin"];
    let _data = { ...broadcastMessageData };
    let replace = "";
    let re, input;

    for (var i = 1; i <= _data.language.language.parameters.body.length; i++) {
      if (id === "") {
        input = "preview-body-" + String(i - 1);
        input = document.getElementById(input).value;
      } else {
        input = value.value;
        onSelectCustomVariable("body", value, index);
      }

      if (input.includes("    ") || input.includes("\t") || input.includes("&emsp;") || input.includes("\n")) {
        doToast(`Parameter {{${_data.language.language.parameters.body[i - 1].name}}} cannot have new-line/tab characters or 4 consecutive spaces`, "fail");
      }

      let variableName = _data.language.language.parameters.body[i - 1].name;
      replace = `\\{\\{${variableName}\\}\\}`;
      re = new RegExp(replace, "g");

      if (id === "") {
        _data.language.language.parameters.body[i - 1].value = input;
      } else {
        // if custom contact
        if (broadcastMessageData.customContacts.contactsFileName) {
          let idxBodyParam = broadcastMessageData.customContacts.header.findIndex(v => v.value === value.value);
          input = broadcastMessageData.customContacts.body[0][idxBodyParam];

          _data.language.language.parameters.body[index].value = input;
        }
      }

      if (input) {
        if (broadcastMessageData.customContacts.contactsFileName) {
          if (index === (i - 1)) {
            sourceText = sourceText.replace(re, input);
          } else {
            if (broadcastMessageData.language.language.parameters.body[i - 1].value) {
              sourceText = sourceText.replace(re, broadcastMessageData.language.language.parameters.body[i - 1].value)
            }
          }
        } else {
          sourceText = sourceText.replace(re, input);
        }
      } else {
        let param = broadcastMessageData.language.language.parameters.body.filter(
          (item) => item.number === i
        )[0];
        sourceText = sourceText.replace(re, `{{${param.name}}}`);
      }
    }

    if (id === "") {
      _data.language.language.bodyTextNamed = sourceText;
    } else {
      if (broadcastMessageData.customContacts.contactsFileName) {
        _data.language.language.bodyTextNamed = sourceText;
      }
    }

    setBroadcastMessageData(_data);
  };

  let insertButtonVariable = (id = "", value = "", index = "") => {
    // let sourceText = broadcastMessageData.language.language["bodyTextNamedOrigin"];
    let _data = { ...broadcastMessageData };
    // let replace = "";
    let input;

    for (var i = 1; i <= _data.language.language.parameters.buttons.length; i++) {
      if (id === "") {
        input = "preview-buttons-" + String(i - 1);
        input = document.getElementById(input).value;
      } else {
        input = value.value;
        onSelectCustomVariable("buttons", value, index);
      }

      if (input.includes("    ") || input.includes("\t") || input.includes("&emsp;") || input.includes("\n")) {
        doToast(`Parameter {{${_data.language.language.parameters.buttons[i - 1].name}}} cannot have new-line/tab characters or 4 consecutive spaces`, "fail");
      }

      // let variableName = _data.language.language.parameters.buttons[i - 1].name;
      // replace = `\\{\\{${variableName}\\}\\}`;
      // re = new RegExp(replace, "g");

      if (id === "") {
        _data.language.language.parameters.buttons[i - 1].value = input;
      }

      // if (input) {
      //   sourceText = sourceText.replace(re, input);
      // } else {
      //   let param = broadcastMessageData.language.language.parameters.body.filter(
      //     (item) => item.number === i
      //   )[0];
      //   sourceText = sourceText.replace(re, `{{${param.name}}}`);
      // }
    }

    // if(id === "") {
    //   _data.language.language.bodyTextNamed = sourceText;
    // }

    setBroadcastMessageData(_data);
  };

  let isDisabledSend = () => {
    let disabled = false;

    if (broadcastMessageData.selectedChannel.value === "whatsappba") {
      broadcastMessageData.language.language.parameters.body.map((item, idx) => {
        let _val = broadcastMessageData.language.language.parameters.body[idx].value;
        if (_val === "" || !_val) {
          disabled = true;
        }

        if (_val && (_val.includes("    ") || _val.includes("\t") || _val.includes("&emsp;") || _val.includes("\n"))) {
          disabled = true;
        }

        return null;
      })

      broadcastMessageData.language.language.parameters.header.map((item, idx) => {
        let _val = broadcastMessageData.language.language.parameters.header[idx].value;
        if (_val === "" || !_val) {
          disabled = true;
        }

        if (_val && (_val.includes("    ") || _val.includes("\t") || _val.includes("&emsp;") || _val.includes("\n"))) {
          disabled = true;
        }

        return null;
      })

      if (broadcastMessageData.isDynamicMedia && broadcastMessageData.language.language.headerTextNamed === "" && (broadcastMessageData.broadcastMessageData && broadcastMessageData.customContacts.contactsFileName)) {
        disabled = true;
      }

      if (percentageUpload) {
        disabled = true;
      }

      if (broadcastMessageData.language.language.cardParameters && broadcastMessageData.language.language.cardParameters.length > 0) {
        if (broadcastMessageData.customContacts && broadcastMessageData.customContacts.contactsFileName) {
          broadcastMessageData.language.language.cards.map((v) => {
            if (!v.headerDropdownVal) {
              disabled = true;
            }

            return null;
          })
        }

        broadcastMessageData.language.language.cardParameters.map((vCardParam) => {
          if (vCardParam.buttons.length > 0) {
            vCardParam.buttons.map((vCardParamButtons) => {
              if (!vCardParamButtons.value || vCardParamButtons.value === "") {
                disabled = true;
              }

              return null;
            })
          }

          if (vCardParam.body.length > 0) {
            vCardParam.body.map((vCardParamBody) => {
              if (!vCardParamBody.value || vCardParamBody.value === "") {
                disabled = true;
              }

              return null;
            })
          }

          return null;
        })
      }
    } else {
      if (!checkedCheckbox) {
        disabled = true;
      }
    }

    return disabled;
  }

  // let getWhatsappPriceList = () => {
  //   setLoadingWhatsappPrice(true);

  //   StartConversationServices.postGetPriceList(orgID, (response) => {
  //       let dataResult = response.dataResult;
  //       let dataResultData = dataResult.data;

  //       if(dataResult.status === 200) {
  //           setLoadingWhatsappPrice(false);
  //           let _modalStartConversationBalance = {...props.modalStartConversationBalance};
  //           _modalStartConversationBalance.confirmation.show = true;
  //           _modalStartConversationBalance.confirmation.participant = broadcastMessageData.contacts.length;
  //           _modalStartConversationBalance.confirmation.isLoading = false;
  //           _modalStartConversationBalance.confirmation.priceList = dataResultData;
  //           _modalStartConversationBalance.confirmation.messageType = broadcastMessageData.messageType;
  //           _modalStartConversationBalance.confirmation.confirmAction = () => {
  //             onSubmitBroadcastMessage();
  //           };
  //           props.setModalStartConversationBalance(_modalStartConversationBalance);
  //       }else {
  //           doToast(dataResult.error.message, "fail")
  //           setLoadingWhatsappPrice(false);
  //       }
  //   })
  // }

  let clickSendMessage = () => {
    onSubmitBroadcastMessage();

    // if(broadcastMessageData.selectedChannel.value === "whatsappba") {
    //   onSubmitBroadcastMessage();
    // }else {
    //   getWhatsappPriceList();
    // }
  }

  let ModalSending = () => (
    <Modal className="modal-sending" isOpen={isShowModalSending}>
      <ModalBody>
        <div className="lds-ring">
          <div /><div /><div /><div />
        </div>

        <p>
          Sending... Please make sure you have a stable connection.
        </p>
      </ModalBody>
    </Modal>
  )

  let doUpload = (file) => {
    setIsUploading(true);

    let uploadData = {
      file: file,
      caption: "",
      room: ""
    };

    tapCoreMessageManager.uploadChatFile(uploadData, {
      onProgress: (percentage, bytes) => {
        setPercentageUpload(percentage);

        if (percentage === 100) {
          setPercentageUpload(false);
        }
      },

      onSuccess: (response) => {
        if (response) {
          setIsUploading(false);
          let _broadcastMessageData = { ...broadcastMessageData };
          _broadcastMessageData.language.language.file = file;
          _broadcastMessageData.language.language.headerTextNamed = response.fileURL;
          _broadcastMessageData.language.language.parameters.header[0].value = response.fileURL;
          setBroadcastMessageData(_broadcastMessageData)
        }
      },

      onError: (error) => {
        // setIsDisabledUpload(false);
        doToast(error, "fail");
        console.log(error);
      }
    })
  }


  let taptalkAuthTicket = (file) => {
    let _broadcastMessageData = { ...broadcastMessageData };
    _broadcastMessageData.language.language.file = file;
    setBroadcastMessageData(_broadcastMessageData)

    ChatService.postRequestAuthTicket(orgID, (response) => {
      if (response.dataResult.error.code === "") {
        taptalk.authenticateWithAuthTicket(response.dataResult.data.ticket, false, {
          onSuccess: () => {
            doUpload(file);
          },
          onError: (errorCode, errorMessage) => {
            doToast(errorMessage, 'fail')
            console.log(errorCode, errorMessage);
          }
        })
      } else {
        doToast(response.dataResult.error.message, 'fail');
      }
    })
  }

  let runTaptalkRequestAuthTicket = (file) => {
    ChatService.postProjectConfig(orgID, (response) => {
      let data = response.dataResult.data.tapTalk;

      if (response.dataResult.error.code === "") {
        taptalk.init(data.appKeyID, data.appKeySecret, data.apiURL);
        taptalkAuthTicket(file);
      } else {
        doToast(response.dataResult.error.message, "fail");
      }
    });
  }

  let runUpload = (file) => {
    let _broadcastMessageData = { ...broadcastMessageData };
    _broadcastMessageData.language.language.file = file;
    setBroadcastMessageData(_broadcastMessageData)

    setPercentageUpload(1);

    if (props.withoutRequestAuthTicketProps) {
      doUpload(file);
    } else {
      runTaptalkRequestAuthTicket(file);
    }
  }

  let onChangeFile = (e, sizeLimit) => {
    let files = [];
    files.push(e.target.files[0]);

    HelperUpload.checkFileAllowed(files, {
      onAllowed: () => {
        HelperUpload.checkFileLimit(files, {
          onAllowed: () => {
            HelperUpload.checkFileSizeLimitForMedia([], files, {
              onAllowed: (arrayOfMedia) => {
                // runImageCompress(arrayOfMedia[0], (res) => {
                //    runUpload(res);
                // })
                let _broadcastMessageData = { ...broadcastMessageData };
                _broadcastMessageData.language.language.file = arrayOfMedia[0];
                _broadcastMessageData.language.language.headerTextNamed = "";
                setBroadcastMessageData(_broadcastMessageData)

                runUpload(arrayOfMedia[0])
                // runUploadImage(arrayOfMedia[0])
              },
              onReachLimit: (message) => {
                doToast(message, "fail");
              }
            }, sizeLimit)
          },
          onReachLimit: (message) => {
            doToast(message, "fail");
          }
        })
      },
      onFileTypeDidntMatch: (message) => {
        doToast(message, "fail");
      }
    }, broadcastMessageData.language.language.headerFormat === "image" ? "image" : (broadcastMessageData.language.language.headerFormat === "video" ? "video" : "pdf"))

    e.target.value = null;
  }

  let onChangeCarouselCardParamBody = (e, parentIndex, cardIndex) => {
    let isCustomContact = broadcastMessageData.contactType === "contacts";

    let sourceText = broadcastMessageData.language.language.cards[parentIndex]["bodyTextIndexed"];

    let _data = { ...broadcastMessageData };
    let replace = "";
    let re, input;

    for (var i = 1; i <= _data.language.language.cardParameters[parentIndex]["body"].length; i++) {
      if (!isCustomContact) {
        input = e.target.value;
      } else {
        input = e.value;
        onSelectCustomVariableCarousel("carouselBodyParam", e, parentIndex, cardIndex);
      }

      if (input.includes("    ") || input.includes("\t") || input.includes("&emsp;") || input.includes("\n")) {
        doToast(`Parameter {{${_data.language.language.cardParameters[parentIndex]["body"][cardIndex].name}}} cannot have new-line/tab characters or 4 consecutive spaces`, "fail");
      }

      replace = `\\{\\{${i}\\}\\}`;
      re = new RegExp(replace, "g");

      if (!isCustomContact) {
        _data.language.language.cardParameters[parentIndex]["body"][cardIndex].value = input;
      } else {
        if (broadcastMessageData.customContacts.contactsFileName) {
          let idxBodyParam = broadcastMessageData.customContacts.header.findIndex(v => v.value === e.value);
          input = broadcastMessageData.customContacts.body[0][idxBodyParam];

          _data.language.language.cardParameters[parentIndex]["body"][cardIndex].value = input;
        }
      }

      if (input) {
        sourceText = sourceText.replace(re, input);
      } else {
        sourceText = sourceText.replace(re, `{{${_data.language.language.cardParameters[parentIndex]["body"][cardIndex].name}}}`);
      }
    }

    _data.language.language.cards[parentIndex].bodyTextNamed = sourceText;

    setBroadcastMessageData(_data);
  }

  let onChangeCarouselCardParamButton = (e, parentIndex, cardIndex) => {
    let isCustomContact = broadcastMessageData.contactType === "contacts";
    let _data = { ...broadcastMessageData };

    // if(isCustomContact) {
    //   onSelectCustomVariableCarousel("carouselButtonParamDynamicURL", e, parentIndex, cardIndex);
    // }

    if (!isCustomContact) {
      _data.language.language.cardParameters[parentIndex]["buttons"][cardIndex].value = e.target.value;
    } else {
      onSelectCustomVariableCarousel("carouselButtonParamDynamicURL", e, parentIndex, cardIndex);
    }

    setBroadcastMessageData(_data);
  }

  let doUploadFileCarousel = (index, file) => {
    let type = "";

    if (file.type.split("/")[0] !== "video" && file.type.split("/")[0] !== "image") {
      type = "file";
    } else {
      type = file.type.split("/")[0];
    }

    // var formData = new FormData();
    // formData.append("fileType", type);
    // formData.append("file", file);

    // IntegrationWhatsappBusinessServices.uploadFile(props.match.params.orgID, formData, (response) => {
    //   let dataResult = response.dataResult;

    //   if (dataResult.error.message === "") {
    //     let _data = { ...broadcastMessageData };
    //     _data.language.language.cards[index].lastUploadFile = {
    //       time: new Date().valueOf(),
    //       url: dataResult.data.fileURL
    //     };

    //     _data.language.language.cards[index].isWaitUploadHeaderFile = false;
    //     setBroadcastMessageData(_data);
    //   } else {
    //     doToast(dataResult.error.message, "fail");
    //   }

    //   // setIsUploadingFile(false);
    // });
    let uploadData = {
      file: file,
      caption: "",
      room: ""
    };

    tapCoreMessageManager.uploadChatFile(uploadData, {
      onProgress: (percentage, bytes) => {
        
      },

      onSuccess: (response) => {
        if (response) {
          let _data = { ...broadcastMessageData };
          _data.language.language.cards[index].lastUploadFile = {
            time: new Date().valueOf(),
            url: response.fileURL
          };

          _data.language.language.cards[index].isWaitUploadHeaderFile = false;
          setBroadcastMessageData(_data);
        }
      },

      onError: (error) => {
        // setIsDisabledUpload(false);
        doToast(error, "fail");
        console.log(error);
      }
    })
  }

  let onChangeCarouselCardMedia = (e, index, format) => {
    let file = e.target.files[0];
    let type = file.type.split("/")[0];
    let _data = { ...broadcastMessageData };
    let fullType = file.type;
    let fileError = "File format is not allowed";

    if (fullType === "image/jpeg" || fullType === "image/png" || fullType === "video/mp4" || fullType === "image/3gpp") {
      fileError = "";
    }

    if (fileError === "") {
      if (type === format) {
        HelperUpload.checkFileSizeLimitForMedia([], e.target.files, {
          onReachLimit: (mess) => {
            doToast(mess, "fail");
          },
          onAllowed: (arrayOfMedia) => {
            _data.language.language.cards[index].isWaitUploadHeaderFile = true;
            _data.language.language.cards[index].headerLocalFile = arrayOfMedia[0];
            _data.language.language.cards[index].headerFileURL = "";
            _data.language.language.cards[index].lastUploadFile = undefined;
            // let _error = { ...addMetaCardError };

            // _addMetaCard.media = arrayOfMedia[0];

            // _error.media = "";

            setBroadcastMessageData(_data);
            // setAddMetaCardError(_error);

            doUploadFileCarousel(index, arrayOfMedia[0]);
          }
        }, type === "image" ? 5242880 : 16777216);
      } else {
        doToast(`Only ${format} file are allowed`, "fail");
      }
    } else {
      doToast(fileError, "fail");
    }
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        <BackButton text="Back to Broadcast Message" onClick={() => back(broadcastMessageData)} />
        {/* 
        {currentMembership &&
          <ModalBroadcastBalance
            currentMembership={currentMembership}
            {...props}
          />
        } */}

        <div className="sectionWrap preview-broadcast-message-wrapper">
          <TermsWhatsappModal
            isShowModalTermsProps={showModalTerms}
            toggleModalTermsProps={toggleModalTerms}
            onClickAcceptProps={onSubmitBroadcastMessage}
            isLoadingAcceptProps={false} //isWaitResponseBroadcastMessage
            isWaba={broadcastMessageData ? (broadcastMessageData.selectedChannel.value === "whatsappba") : false}
            withClose
          />

          <ModalSending />

          <div className="form-title-wrapper">
            <FiEye />
            <b>Preview Message</b>

            {broadcastMessageData &&
              (broadcastMessageData.selectedChannel.value === "whatsapp" &&
                (!loadingWhatsappPrice ?
                  <ButtonWithLoadingOrIcon
                    onClickAction={clickSendMessage}
                    className="orange-button main-button-40 send-message-button"
                    text="Send Message"
                    icon={{
                      type: "img",
                      src: isDisabledSend() ? AirplaneGrey : Airplane
                    }}
                    position="left"
                    isDisabled={isDisabledSend()}
                  />
                  :
                  <ButtonWithLoadingOrIcon
                    isLoading
                    loadingColor="gray"
                    className="orange-button main-button-40 send-message-button"
                    text="Send Message"
                    position="left"
                    isDisabled={isDisabledSend() || loadingWhatsappPrice}
                  />
                )
              )
            }
          </div>

          {/* {isLoadTemplateDetail || retriveDataError.code ? 
                    <RetrieveDataLoading 
                        isLoading={isLoadTemplateDetail}
                        errorMessage={retriveDataError.message} 
                    />
                    : */}
          {broadcastMessageData &&
            <>
              <div className="form-content-wrapper">
                {broadcastMessageData.selectedChannel.value === "whatsapp" &&
                  <div className="agree-wrapper">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        id="agree"
                        onChange={(e) => changeCheckBoxTerms(e)}
                        checked={checkedCheckbox}
                        disabled={loadingWhatsappPrice}
                      />
                      <label htmlFor="agree"></label>
                    </div>

                    <p>
                      I accept and agree to the <b className="button-term-condition" onClick={toggleModalTerms}>terms and conditions.</b>
                    </p>
                  </div>
                }

                {broadcastMessageData.scheduleDateSelect &&
                  <p className="broadcast-title-time">
                    <b>{broadcastMessageData.name}</b> will be sent at <b>{HelperDate.formatToString(new Date(broadcastMessageData.scheduleDateSelect), "dd MMMM yyyy")}, {broadcastMessageData.scheduleTime}</b>
                  </p>
                }

                <div className="chatroom-preview-wrapper">
                  <div className="chatroom-preview-container">
                    <div className="info-message-bubble-wrapper">
                      <div className="info-message-bubble">
                        <div />
                      </div>
                    </div>

                    <div className="left-message-bubble-wrapper">
                      <div className="left-message-bubble">
                        <div className="user-wrapper">
                          <FiUser />
                        </div>

                        <div className="left-message-chat-bubble">
                          <div />

                          <div />
                        </div>
                      </div>
                    </div>

                    {broadcastMessageData &&
                      (broadcastMessageData.selectedChannel.value === "whatsappba" ?
                        renderBubbleBa(broadcastMessageData)
                        :
                        broadcastMessageData.messages.map((val, idx) => {
                          return (
                            renderBubble(val)
                          )
                        })
                      )
                    }
                  </div>

                  <div className="input-text-wrapper">
                    <div className="input-text-container">
                      <div />

                      <FiPaperclip />
                    </div>

                    <div className="submit-button-airplane">
                      <img src={Airplane} alt="" />
                    </div>
                  </div>
                </div>

                {broadcastMessageData.selectedChannel.value === "whatsappba" &&
                  <>
                    {/* {(
                                    broadcastMessageData.language.language.parameters.header.length > 0 ||
                                    broadcastMessageData.language.language.parameters.body.length > 0
                                  ) &&
                                    <div className="preview-message-variable">
                                        <label className="variable-title">
                                          <b>Variables</b> 
                                          <span className="grey-font">
                                            ({
                                              broadcastMessageData.language.language.parameters.header.length + 
                                              broadcastMessageData.language.language.parameters.body.length
                                            })
                                          </span>
                                        </label>
                                    </div>
                                  } */}
                    {(
                      broadcastMessageData.language.language.parameters.header.length > 0
                    ) &&
                      <div className="preview-message-variable">
                        <label className="variable-title">
                          <b>Header Parameter{broadcastMessageData.language.language.parameters.header.length > 1 ? "s" : ""}</b>
                          {broadcastMessageData.language.language.parameters.header.length &&
                            <span className="grey-font">
                              ({broadcastMessageData.language.language.parameters.header.length})
                            </span>
                          }
                        </label>
                      </div>
                    }

                    {(broadcastMessageData.selectedChannel.value === TAPLIVE_MEDIUM.whatsappba && broadcastMessageData.isDynamicMedia) &&
                      (broadcastMessageData.isDynamicMedia &&
                        <div className="media-upload-wrapper">
                          <div className="media-upload-wrapper-left">
                            <label>
                              {/* <b>Media</b> */}
                              <b>{`{{${broadcastMessageData.language.language.parameters.header[0].name}}}`}</b>
                            </label>

                            {/* {broadcastMessageData.language.language.file &&
                                            <button 
                                              className="replace-media light-orange-button" 
                                              onClick={() => !isUploading && document.querySelector("#upload-media").click()}
                                            >
                                              <FiUpload />
                                              {!isUploading ?
                                                `Replace ${broadcastMessageData.language.language.headerFormat}`
                                                :
                                                `Uploading ${broadcastMessageData.language.language.headerFormat}`
                                              }
                                            </button>
                                          } */}
                          </div>


                          {broadcastMessageData.contactType === "contacts" ?
                            <div className="media-upload-wrapper-right custom-variable-wrapper">
                              <div className="input-param-wrapper">
                                <CustomSelectOption
                                  isDisabled={false}
                                  optionListProps={broadcastMessageData.customContacts.header}
                                  valueDropdownProps={broadcastMessageData.language.language.parameters.header[0].dropdownVal}
                                  placeholderProps={"Please Select"}
                                  // onClickDropDownListOptionProps={() => onSelectCustomVariable}
                                  onClickDropDownListOptionProps={(e) => insertHeaderVariable(`preview-header-0`, e, 0)}
                                  specificClassNameProps="custom-select-custom-variable"
                                />
                              </div>
                            </div>
                            :
                            <div className="media-upload-wrapper-right">
                              <input
                                type="file"
                                id="upload-media"
                                accept={
                                  broadcastMessageData.language.language.headerFormat === "document" ?
                                    "application/pdf"
                                    :
                                    broadcastMessageData.language.language.headerFormat === "image" ?
                                      "image/*"
                                      :
                                      "video/*"
                                }
                                onChange={(e) => onChangeFile(e, broadcastMessageData.language.language.headerFormat === "document" ? WHATSAPP_FILE_SIZE_LIMIT.document(taptalk.getProjectConfigs().core.chatMediaMaxFileSize) : WHATSAPP_FILE_SIZE_LIMIT.media(taptalk.getProjectConfigs().core.chatMediaMaxFileSize))}
                              />

                              {broadcastMessageData.language.language.file ?
                                (broadcastMessageData.language.language.headerFormat === "image" ?
                                  <div className="image-video-wrapper b">
                                    <img src={window.URL.createObjectURL(broadcastMessageData.language.language.file)} alt="" />

                                    {percentageUpload &&
                                      <div className="progress-wrapper">
                                        <CircularProgressbar value={percentageUpload} />
                                      </div>
                                    }
                                  </div>
                                  :
                                  (broadcastMessageData.language.language.headerFormat === "video" ?
                                    <div className="image-video-wrapper c">
                                      <video src={broadcastMessageData.language.language.headerTextNamed ? broadcastMessageData.language.language.headerTextNamed : ""} />

                                      {percentageUpload &&
                                        <div className="progress-wrapper">
                                          <CircularProgressbar value={percentageUpload} />
                                        </div>
                                      }
                                    </div>
                                    :
                                    <div className="document-wrapper">
                                      <FiFile />
                                      {broadcastMessageData.language.language.file.name} {percentageUpload ? <b>- {percentageUpload}%</b> : ""}
                                    </div>

                                  )
                                )
                                :
                                <ButtonWithLoadingOrIcon
                                  className="main-button-40 light-orange-button"
                                  text={`Upload ${broadcastMessageData.language.language.headerFormat}`}
                                  position="left"
                                  icon={{
                                    type: "svg",
                                    src: FiUpload
                                  }}
                                  onClickAction={() => document.querySelector("#upload-media").click()}
                                />
                              }

                              <p className="upload-info">
                                {broadcastMessageData.language.language.file &&
                                  <button
                                    className="replace-media light-orange-button"
                                    onClick={() => !isUploading && document.querySelector("#upload-media").click()}
                                  >
                                    <FiUpload />
                                    {!isUploading ?
                                      `Replace ${broadcastMessageData.language.language.headerFormat}`
                                      :
                                      `Uploading ${broadcastMessageData.language.language.headerFormat}`
                                    }
                                  </button>
                                }

                                <FiAlertCircle />
                                {broadcastMessageData.language.language.headerFormat === "image" ?
                                  <b>Allowed file type: image</b>
                                  :
                                  (broadcastMessageData.language.language.headerFormat === "video" ?
                                    <b>Allowed file type: video</b>
                                    :
                                    <b>Allowed file type: pdf</b>
                                  )
                                }
                              </p>
                            </div>
                          }
                        </div>
                      )
                    }

                    {broadcastMessageData.language.language.parameters.header.map((item, idx) => {
                      return (
                        (item.type === "text" &&
                          <div
                            className="preview-message-param"
                            key={`header-param-${idx}`}
                          >
                            <label>
                              <b>{`{{${item.name}}}`}</b>
                            </label>
                            {broadcastMessageData.contactType === "contacts" ?
                              <div className="custom-variable-wrapper">
                                <div className="input-param-wrapper">
                                  <CustomSelectOption
                                    isDisabled={false}
                                    optionListProps={broadcastMessageData.customContacts.header}
                                    valueDropdownProps={broadcastMessageData.language.language.parameters.header[idx].dropdownVal}
                                    placeholderProps={"Please Select"}
                                    // onClickDropDownListOptionProps={() => onSelectCustomVariable}
                                    onClickDropDownListOptionProps={(e) => insertHeaderVariable(`preview-header-${idx}`, e, idx)}
                                    specificClassNameProps="custom-select-custom-variable"
                                  />
                                </div>
                              </div>
                              :
                              <div className="input-param-wrapper">
                                <input
                                  className="preview-message-input"
                                  id={"preview-header-" + idx}
                                  placeholder="E.g John Ham"
                                  onChange={() => insertHeaderVariable()}
                                  // disabled={isLoadingWhatsappStatus}
                                  value={broadcastMessageData.language.language.parameters.header[idx].value || ''}
                                  disabled={isWaitResponseBroadcastMessage}
                                />


                                <p className="input-info-text">
                                  <FiInfo />
                                  <b>Cannot have new-line/tab characters or 4 consecutive spaces</b>
                                </p>
                              </div>
                            }
                          </div>
                        )
                      );
                    })}

                    {(
                      broadcastMessageData.language.language.parameters.body.length > 0
                    ) &&
                      <div className="preview-message-variable">
                        <label className="variable-title">
                          <b>Body Parameter{broadcastMessageData.language.language.parameters.body.length > 1 ? "s" : ""}</b>
                          <span className="grey-font">
                            ({broadcastMessageData.language.language.parameters.body.length})
                          </span>
                        </label>
                      </div>
                    }

                    {broadcastMessageData.language.language.parameters.body.map((item, idx) => {
                      return (
                        (item.type === "text" &&
                          <div
                            className="preview-message-param"
                            key={`body-param-${idx}`}
                          >
                            <label>
                              <b>{`{{${item.name}}}`}</b>
                            </label>

                            {broadcastMessageData.contactType === "contacts" ?
                              <div className="custom-variable-wrapper">
                                <div className="input-param-wrapper">
                                  <CustomSelectOption
                                    isDisabled={false}
                                    optionListProps={broadcastMessageData.customContacts.header}
                                    valueDropdownProps={broadcastMessageData.language.language.parameters.body[idx].dropdownVal}
                                    placeholderProps={"Please Select"}
                                    // onClickDropDownListOptionProps={onSelectCustomVariable}
                                    onClickDropDownListOptionProps={(e) => insertBodyVariable(`preview-body-${idx}`, e, idx)}
                                    specificClassNameProps="custom-select-custom-variable"
                                  />
                                </div>
                              </div>
                              :
                              <div className="input-param-wrapper">
                                <input
                                  className="preview-message-input"
                                  id={"preview-body-" + idx}
                                  placeholder="E.g John Ham"
                                  onChange={() => insertBodyVariable()}
                                  // disabled={isLoadingWhatsappStatus}
                                  value={broadcastMessageData.language.language.parameters.body[idx].value || ''}
                                  disabled={isWaitResponseBroadcastMessage}
                                />

                                <p className="input-info-text">
                                  <FiInfo />
                                  <b>Cannot have new-line/tab characters or 4 consecutive spaces</b>
                                </p>
                              </div>
                            }
                          </div>
                        )
                      );
                    })}

                    {(
                      broadcastMessageData.language.language.parameters.buttons &&
                      broadcastMessageData.language.language.parameters.buttons.length > 0 &&
                      broadcastMessageData.messageTemplate.channel.categoryCode !== "AUTHENTICATION"
                    ) &&
                      <>
                        <div className="preview-message-variable">
                          <label className="variable-title">
                            <b>Button Parameter{broadcastMessageData.language.language.parameters.buttons.length > 1 ? "s" : ""}</b>
                            <span className="grey-font">
                              ({broadcastMessageData.language.language.parameters.buttons.length})
                            </span>
                          </label>
                        </div>

                        {broadcastMessageData.language.language.parameters.buttons.map((item, idx) => {
                          return (
                            (item.type === "text" &&
                              <div
                                className="preview-message-param"
                                key={`buttons-param-${idx}`}
                              >
                                <label>
                                  <b>{`{{${item.name}}}`}</b>
                                </label>

                                {broadcastMessageData.contactType === "contacts" ?
                                  <div className="custom-variable-wrapper">
                                    <div className="input-param-wrapper">
                                      <CustomSelectOption
                                        isDisabled={false}
                                        optionListProps={broadcastMessageData.customContacts.header}
                                        valueDropdownProps={broadcastMessageData.language.language.parameters.buttons[idx].dropdownVal}
                                        placeholderProps={"Please Select"}
                                        // onClickDropDownListOptionProps={onSelectCustomVariable}
                                        onClickDropDownListOptionProps={(e) => insertButtonVariable(`preview-buttons-${idx}`, e, idx)}
                                        specificClassNameProps="custom-select-custom-variable"
                                      />
                                    </div>
                                  </div>
                                  :
                                  <div className="input-param-wrapper">
                                    <input
                                      className="preview-message-input"
                                      id={"preview-buttons-" + idx}
                                      placeholder="Input value"
                                      onChange={(e) => insertButtonVariable()}
                                      // disabled={isLoadingWhatsappStatus}
                                      value={broadcastMessageData.language.language.parameters.buttons[idx].value || ''}
                                      disabled={isWaitResponseBroadcastMessage}
                                    />

                                    <p className="input-info-text">
                                      <FiInfo />
                                      <b>Cannot have new-line/tab characters or 4 consecutive spaces</b>
                                    </p>
                                  </div>
                                }
                              </div>
                            )
                          );
                        })}
                      </>
                    }

                    {(
                      broadcastMessageData.language.language.cardParameters &&
                      broadcastMessageData.language.language.cardParameters.length > 0
                    ) &&
                      <>
                        {broadcastMessageData.language.language.cardParameters.map((vCard, iCard) => {
                          return (
                            <div className="preview-message-variable" key={`preview-message-variable-${iCard}`}>
                              <label className="variable-title">
                                <b>Card #{iCard + 1} Parameters</b>
                              </label>

                              <div className="carousel-card-param-input-wrapper">
                                <>
                                  <label className="carousel-card-param-input-title">
                                    <b>Header Parameters</b>
                                  </label>

                                  <div className="carouel-card-param-input-box">
                                    <div className="carouel-card-param-input-box-left">
                                      <b>{`media`}</b>
                                    </div>

                                    {broadcastMessageData.contactType === "contacts" ?
                                      <div className="carouel-card-param-input-box-right">
                                        <div className="media-upload-wrapper-right custom-variable-wrapper">
                                          <div className="custom-variable-wrapper input-param-wrapper">
                                            <CustomSelectOption
                                              isDisabled={false}
                                              optionListProps={broadcastMessageData.customContacts.header}
                                              valueDropdownProps={broadcastMessageData.language.language.cards[iCard].headerDropdownVal ? broadcastMessageData.language.language.cards[iCard].headerDropdownVal : { value: "", label: "" }}
                                              placeholderProps={"Please Select"}
                                              onClickDropDownListOptionProps={(e) => onSelectCustomVariableCarousel("carouselHeaderMedia", e, iCard)}
                                              specificClassNameProps="custom-select-custom-variable"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      :
                                      <div className="carouel-card-param-input-box-right carousel-card-param-input-media">
                                        <div className="carousel-card-param-input-media-outer">
                                          <div className="carousel-card-param-input-media-image-inner">
                                            {broadcastMessageData.language.language.cards[iCard].headerFormat === "video" ?
                                              <video
                                                src={
                                                  broadcastMessageData.language.language.cards[iCard].headerFileURL === "" ?
                                                    ""
                                                    :
                                                    broadcastMessageData.language.language.cards[iCard].headerFileURL
                                                }
                                                alt=""
                                              />
                                              :
                                              <img
                                                src={
                                                  broadcastMessageData.language.language.cards[iCard].headerLocalFile ?
                                                    window.URL.createObjectURL(broadcastMessageData.language.language.cards[iCard].headerLocalFile)
                                                    :
                                                    broadcastMessageData.language.language.cards[iCard].headerFileURL
                                                }
                                                alt=""
                                              />
                                            }

                                            {broadcastMessageData.language.language.cards[iCard].isWaitUploadHeaderFile ?
                                              <div className="lds-ring">
                                                <div /><div /><div /><div />
                                              </div>
                                              :
                                              (broadcastMessageData.language.language.cards[iCard].headerFormat === "video" ?
                                                <FiVideo />
                                                :
                                                <FiImage />
                                              )
                                            }
                                          </div>

                                          <input onChange={(e) => onChangeCarouselCardMedia(e, iCard, broadcastMessageData.language.language.cards[iCard].headerFormat)} type="file" className="force-hide" id={`carousel-input-file-${iCard}`} accept={`${broadcastMessageData.language.language.cards[iCard].headerFormat}/*`} />

                                          <ButtonWithLoadingOrIcon
                                            className="light-orange-button main-button-22"
                                            text={broadcastMessageData.language.language.cards[iCard].isWaitUploadHeaderFile ? `Uploading ${broadcastMessageData.language.language.cards[iCard].headerFormat === "image" ? "Image" : "Video"}` : `Replace Media`}
                                            icon={{
                                              type: "svg",
                                              src: FiUploadCloud
                                            }}
                                            position="left"
                                            onClickAction={() => {
                                              if (!broadcastMessageData.language.language.cards[iCard].isWaitUploadHeaderFile) {
                                                let el = document.querySelector(`#carousel-input-file-${iCard}`);

                                                if (el) {
                                                  el.click();
                                                }
                                              }
                                            }}
                                            isDisabled={isWaitResponseBroadcastMessage}
                                          />

                                          <ErrorOrInfoComp
                                            text={`
                                              Allowed type: 
                                              ${broadcastMessageData.language.language.cards[iCard].headerFormat === "image" ?
                                                `image/jpeg or image/png`
                                                :
                                                `video/3gpp or video/mp4`
                                              } 
                                              <br /> Maximum upload size for image: ${broadcastMessageData.language.language.cards[iCard].headerFormat === "image" ? "5" : "16"}MB
                                            `}
                                            icon={<FiInfo />}
                                            _className=""
                                          />
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </>

                                {vCard.body.length > 0 &&
                                  <>
                                    <label className="carousel-card-param-input-title carousel-card-param-input-title-body">
                                      <b>Body Parameters</b>
                                    </label>

                                    {vCard.body.map((vBodyParam, iBodyParam) => {
                                      return (
                                        <div className="carouel-card-param-input-box" key={`carousel-body-param-${iBodyParam}`}>
                                          <div className="carouel-card-param-input-box-left">
                                            <b>{`{{${vBodyParam.name}}}`}</b>
                                          </div>

                                          <div className="carouel-card-param-input-box-right">
                                            {broadcastMessageData.contactType === "contacts" ?
                                              <div className="custom-variable-wrapper input-param-wrapper">
                                                <CustomSelectOption
                                                  isDisabled={false}
                                                  optionListProps={broadcastMessageData.customContacts.header}
                                                  valueDropdownProps={vBodyParam.dropdownVal ? vBodyParam.dropdownVal : { value: "", label: "" }}
                                                  placeholderProps={"Please Select"}
                                                  onClickDropDownListOptionProps={(e) => onChangeCarouselCardParamBody(e, iCard, iBodyParam)}
                                                  specificClassNameProps="custom-select-custom-variable"
                                                />
                                              </div>
                                              :
                                              <input
                                                type="text"
                                                placeholder="E.g Luxury Bag"
                                                value={vBodyParam.value ? vBodyParam.value : ""}
                                                onChange={(e) => {
                                                  onChangeCarouselCardParamBody(e, iCard, iBodyParam)
                                                }}
                                                disabled={isWaitResponseBroadcastMessage}
                                              />
                                            }
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </>
                                }

                                {vCard.buttons.length > 0 &&
                                  <>
                                    <label className="carousel-card-param-input-title carousel-card-param-input-title-button">
                                      <b>Button Parameters</b>
                                    </label>

                                    {vCard.buttons.map((vButtonParam, iButtonParam) => {
                                      return (
                                        <div className="carouel-card-param-input-box" key={`carousel-button-param-${iButtonParam}`}>
                                          <div className="carouel-card-param-input-box-left">
                                            <b>{`{{${vButtonParam.name}}}`}</b>
                                          </div>

                                          <div className="carouel-card-param-input-box-right">
                                            {broadcastMessageData.contactType === "contacts" ?
                                              <div className="custom-variable-wrapper input-param-wrapper">
                                                <CustomSelectOption
                                                  isDisabled={false}
                                                  optionListProps={broadcastMessageData.customContacts.header}
                                                  valueDropdownProps={vButtonParam.dropdownVal ? vButtonParam.dropdownVal : { value: "", label: "" }}
                                                  placeholderProps={"Please Select"}
                                                  onClickDropDownListOptionProps={(e) => onChangeCarouselCardParamButton(e, iCard, iButtonParam)}
                                                  specificClassNameProps="custom-select-custom-variable"
                                                />
                                              </div>
                                              :
                                              <input
                                                type="text"
                                                placeholder="E.g Luxury Bag"
                                                value={vButtonParam.value ? vButtonParam.value : ""}
                                                onChange={(e) => {
                                                  onChangeCarouselCardParamButton(e, iCard, iButtonParam)
                                                }}
                                                disabled={isWaitResponseBroadcastMessage}
                                              />
                                            }
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </>
                                }
                              </div>
                            </div>
                          )
                        })}
                      </>
                    }
                  </>
                }

                {broadcastMessageData.selectedChannel.value === "whatsappba" &&
                  (isWaitResponseBroadcastMessage ?
                    <ButtonWithLoadingOrIcon
                      isLoading
                      loadingColor="gray"
                      isDisabled
                      className="orange-button main-button-40"
                      text="Confirm"
                      position="left"
                    />
                    :
                    <ButtonWithLoadingOrIcon
                      onClickAction={toggleModalTerms}
                      className="orange-button main-button-40"
                      text="Confirm"
                      icon={{
                        type: "svg",
                        src: FiCheck
                      }}
                      position="left"
                      isDisabled={isDisabledSend()}
                    />
                  )
                }
              </div>
            </>
          }
          {/* } */}
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};


const mapStateToProps = state => ({
  modalStartConversationBalance: state.modalStartConversationBalance
});

const mapDispatchToProps = {
  setModalStartConversationBalance,
  clearModalStartConversationBalance
};

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastMessage);