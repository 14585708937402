import React from 'react';
import './ChatRoomMessageOut.scss';
import CheckMarkDoubleWhite from '../../../../assets/img/chatroom/icon-double-check-white.svg';
import CheckMarkDoubleDark from '../../../../assets/img/chatroom/icon-double-check-dark.svg';
import CheckMarkDark from '../../../../assets/img/chatroom/icon-check-dark.svg';
import AirplaneDark from '../../../../assets/img/chatroom/icon-airplane-dark.svg';
import MessageDeleteWhite from "../../../../assets/img/icon-notallowed-white.svg";
import Helper from '../../../../helper/HelperChat';
import { CHAT_TYPE } from '../../../../helper/HelperConst';
import { printLastMessageName, isOtherAgent } from '../../../../helper/HelperGeneral';
import { setReplyMessage } from '../../../../redux/actions/reduxActionReplyMessage';
import { connect } from 'react-redux';
import ChatRoomReplyOutMessage from "../chatRoomReplyOutMessage/chatRoomReplyOutMessage/ChatRoomReplyOutMessage";
import ChatRoomMessageLinkPreview from '../chatRoomMessageLinkPreview/ChatRoomMessageLinkPreview';
import MessageAction from '../../../reuseableComponent/messageAction/MessageAction';


var ChatRoomMessageOut = (props) => {
    const ROLE_LIST = {
        agent: {
            value: "agent",
            label: "Agent"
        },
        organization: {
            value: "organization",
            label: "Organization"
        },
        chatbot: {
            value: "chatbot",
            label: "Chatbot"
        }
    }

    let { 
        singleChatDataProps,
        activeUserID,
        onReplyMessage,
        onForwardMessage,
        scrollToReply,
        caseData
    } = props;

    return (
        <div className={`chat-room-message-out-wrapper`}  id={`message-${singleChatDataProps.localID}`}>
            {singleChatDataProps.isDeleted ?
                <div className="message-out-bubble deleted-bubble">
                    <React.Fragment>
                        <img src={MessageDeleteWhite} alt="" className="deleted-icon" />
                        This message was deleted.
                    </React.Fragment>
                </div>
                :

                <div 
                    className={`
                        message-out-bubble 
                        ${isOtherAgent(singleChatDataProps.user.xcUserID.replace("agent:", ""), props.myAgentData) ? "message-bubble-blue" : ""} 
                        ${singleChatDataProps.user.userRole.code === "chatbot" || singleChatDataProps.user.userRole.code === "organization" ? "message-bubble-organization" : ""}
                    `}
                >
                    {(isOtherAgent(singleChatDataProps.user.xcUserID.replace("agent:", ""), props.myAgentData) || (singleChatDataProps.user.userRole.code === "chatbot" || singleChatDataProps.user.userRole.code === "organization")) &&
                        <p className="group-sender-name-wrapper">
                            <b>
                                {singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value &&
                                    <span className="agent-span">{ROLE_LIST.agent.label} - </span>
                                }

                                {singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value &&
                                    <span className="agent-span">{ROLE_LIST.organization.label} - </span>
                                }

                                {singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value &&
                                    <span className="agent-span">{ROLE_LIST.chatbot.label} - </span>
                                }

                                {printLastMessageName(singleChatDataProps, caseData, true)}
                            </b>
                        </p>
                    }

                    {singleChatDataProps.replyTo.localID !== "" &&
                        <ChatRoomReplyOutMessage 
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                            scrollToReply={scrollToReply}
                        />
                    }

                    <span className="message-body" dangerouslySetInnerHTML={ {__html: Helper.lineBreakChatRoom(singleChatDataProps.body)} }></span>
                    
                    {singleChatDataProps.type === CHAT_TYPE.TAPChatMessageTypeLink &&
                        <ChatRoomMessageLinkPreview 
                            isOut={true}
                            message={singleChatDataProps}
                        />
                    }
                    
                    <p className="message-info">
                        {Helper.getDateMonthYear(singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(singleChatDataProps.created)}
                    
                        {(singleChatDataProps.isSending && !singleChatDataProps.isRead) &&
                            <img src={AirplaneDark} alt="" />
                        }

                        {(!singleChatDataProps.isSending && !singleChatDataProps.isDelivered && !singleChatDataProps.isRead) &&
                            <img src={CheckMarkDark} alt="" />
                        }

                        {(!singleChatDataProps.isSending && singleChatDataProps.isDelivered && !singleChatDataProps.isRead) &&
                            <img src={CheckMarkDoubleDark} alt="" />
                        }

                        {singleChatDataProps.isRead &&
                            <img src={CheckMarkDoubleWhite} alt="" />
                        }

                        {/* {!singleChatDataProps.isDelivered &&
                            <img src={CheckMarkDark} alt="" />
                        } */}
        
                    </p>
                    {/* {messageActionView(singleChatDataProps)} */}

                    <MessageAction 
                        singleChatDataProps={singleChatDataProps}
                        _className="message-action-dropdown-right"
                        replyAction={() => onReplyMessage(singleChatDataProps)}
                        forwardAction={() => onForwardMessage(singleChatDataProps)}
                    />
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    myAgentData: state.myAgentData
});

const mapDispatchToProps = {
  setReplyMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageOut);